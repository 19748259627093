// @ts-nocheck
import './data-table.scss';

import * as React from 'react';

import {
  BlueCheckbox,
  EnhancedTableHead,
  TablePaginationActions,
} from '../../App.components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { Link } from 'react-router-dom';
import { confirmationAlert } from '../../../util/systemAlert';
import { deleteMultipleEmployees } from '../../../services/actions/employees';
import { getPharmacy } from '../../../services/actions/pharmacy';
import { myStyles } from './tableStyles';
import { reduxState } from '../../../services/types/reduxStates';

const useStyles = makeStyles(() => createStyles({
  ...myStyles,
  root: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    color: '#707683',
    width: '100%',
    overflowX: 'auto',
  },
}));

interface DataTableProps {
  tableData: any;
}

export const EmployeeDataTable: React.FC<DataTableProps> = (
  props: DataTableProps,
): JSX.Element => {
 
  const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const { tableHeader, tableData } = props.tableData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
  const numberFooter = tableHeader.length + 1;

  React.useEffect(() => {
    dispatch(getPharmacy())
  }, [dispatch]);

  const isSelected = (name: string): boolean => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (_event.target.checked) {
      const newSelecteds = tableData.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    name: string,
  ): void => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRowsPerPage(parseInt(_event.target.value, 10));
    setPage(0);
  };

  const handleMultipleDelete = (): void => {
    const count: string = selected.length > 0 ? 'Employees' : 'Employee';

    confirmationAlert({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Deleted',
        text: `${count} deleted.`,
      },
      callback: async () => dispatch(deleteMultipleEmployees(selected, pharmacy.id)),
      redirect: () => setSelected([]),
    });
  };

  return (
    <Paper className={classes.root}>
      {selected.length > 0 &&
        <EnhancedTableToolbar
          numSelected={selected.length}
          onHandleMultipleDelete={handleMultipleDelete}
          />}
      <TableContainer>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={tableData.length}
          tabaleHeader={tableHeader}
          />
        <TableBody className={classes.tbody}>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              (row: any, index: number): JSX.Element => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.id}
                    className={classes.bodyTr}
                    onClick={(
                      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
                    ): void => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}>
                    <TableCell
                      className={classes.matCheck}
                      component="th"
                      scope="row">
                      <BlueCheckbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                        />
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span
                        className={`ont-badge ${
                          row.status ? 'active' : 'archived'
                          }`}>
                        {' '}
                        {row.status ? 'Active' : 'Archived'}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      {row.role}
                    </TableCell>
                    <TableCell className={classes.matCell}>
                      <div className="user-wrap">
                        <Link
                          to={`/dashboard/employee-details/${row.id}`}
                          className="edit-wrap">
                          <span className="name dot-wrap" title={row.username}>
                            {' '}
                            {row.username}{' '}
                          </span>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span title={row.email} className="dot-wrap">
                        {row.email}{' '}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <Link
                        to={`/dashboard/employee-details/${row.id}`}
                        className="edit-wrap">
                        {/* <div className="edit-text">Edit</div> */}
                        <div className="icon">
                          <EditOutlinedIcon />
                        </div>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          {tableData.length === 0 &&
            <TableRow style={{ height: 48 }}>
              <TableCell colSpan={7} style={{ textAlign: 'center', fontSize: '24px' }}>
                Sorry, no record found
                </TableCell>
            </TableRow>
          }
          {emptyRows > 0 &&
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={numberFooter}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              />
          </TableRow>
        </TableFooter>
      </Table>
        </TableContainer>
    </Paper>
  );
};
