import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
} from '../types/notifications';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import axios from 'axios';
import { logout } from './auth';

export const getNotifications = (data: { pharmacyId: string }): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  if (typeof data.pharmacyId !== 'undefined' || data.pharmacyId) {
    try {
      const response = await axios.get(
        `${API_HOST}/pharmacy_admin/pharmacy/notifications/${data.pharmacyId}`,
      );

      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_NOTIFICATIONS_FAIL,
      });

      if (error.response && error.response.data.code === 401) {
        dispatch(logout());
      }
      throw error;
    }
  }
};

export const deleteNotificationAction = (data: {
  id: string;
  pharmacyId: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/pharmacy/notifications/delete`,
      body,
      config,
    );

    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });

    dispatch(getNotifications({ pharmacyId: data.pharmacyId }));

    return {
      success: true,
      message: response.data,
    };
  } catch (error: any) {
    if (error.response.data && error.response.data.code === 401) {
      dispatch(logout());
    }

    return {
      success: false,
      message: error.response.data.message,
    };
  }
};
