import React from 'react';
import './alert.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Collapse, Grid } from '@material-ui/core';

interface AlertProps {
  message: string;
  status: 'success' | 'warning' | 'danger' | undefined;
  action: () => void;
  open: boolean
};

export const Alert: React.FC<AlertProps> = ({ message, status, action, open}): JSX.Element => 
    <Grid item xs={12}>
      <Collapse in={open}>      
        <div className={`alert alert-${status}`} role="alert">
          <div className="close-alert">
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={action}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
          <p>{message}</p>
        </div>
      </Collapse>
    </Grid>
  
