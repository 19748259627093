import * as React from 'react';
import './email-confirmation.scss';
import {
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Container,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ErrorAlert } from '../../layout/App.components';
import { useQuery } from '../../hooks/useQuery';
import { emailConfirmation } from '../../services/actions/auth';
import { useDispatch } from 'react-redux';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100vh',
    },
    container: {
      flex: '1',
      padding: theme.spacing(5, 3),
      maxWidth: '90%',
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
  }),
);

export const EmailConfirmationPage: React.FC = (): JSX.Element => {
  const [hasError, setError] = React.useState(false);
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const data = {
    token: query.get('token'),
    email: query.get('email'),
    type: query.get('type')
  };

  React.useEffect(() => {
    dispatch(emailConfirmation(data)).then((result: any) => result ? setError(false) : setError(true))
  }, [dispatch, data])


  return (
    <Container maxWidth="sm">
    <div className="email-confirmation-page">
      <div className={classes.root}>
        <Paper className={classes.container}>
          <ErrorAlert />
          {!hasError ? 
            <CheckCircleOutlineIcon className="icon" />
           : 
              <ErrorOutlineIcon className="icon error" />
            }

          <Typography variant="h5" component="h3" style={{textAlign: 'center'}}>
            {!hasError ? 'Email Confirm, Thank You!' : 'Error please try again!'}
          </Typography>

          <Link className="login-link" to="/login">
            Go to login page
          </Link>
        </Paper>
      </div>
    </div>
    </Container>
  );

}
