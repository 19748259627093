import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { reduxState } from '../services/types/reduxStates';

export const useSystemRoles = (role: string): boolean => {
  const [state, setState] = useState<boolean>(false);
  const { user, loading } = useSelector((states: reduxState) => states.user);

  const isValidRole = useCallback(() => {
    if (!loading && user) {
      if (user.role === role) setState(true);
    }
  }, [role, loading, user]);

  useEffect(() => {
    isValidRole();
  });

  return state;
};
