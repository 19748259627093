interface Config {
  HOST: string;
  APP_VERSION: string;
}

const config: Config = {
  HOST: '//api.ontimepharmacyapp.com/api/v1',
  APP_VERSION: '1.2.6'
};

const API_HOST = config.HOST;

const { APP_VERSION } = config;

export { API_HOST, APP_VERSION };
