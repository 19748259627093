import * as React from 'react';
import './account-info.scss';
// import { AccoutnInfo } from '../../../interfaces/account-info';
import { useDemensions } from '../../../hooks/useDemensions';

interface AccountInfoProps {
  info: any;
}

export const AccountInfo: React.FC<AccountInfoProps> = ({
  info,
}: AccountInfoProps): JSX.Element => {
  const onMobile = useDemensions('(max-width:  600px)');
  const onTablet = useDemensions('(max-width: 768px)');

  return (
    <div className={`account-info ${(onMobile || onTablet) && 'mobile'} `}>
      <div className="account-info-wrap">
        {/* <span className="img-wrap">
          <img
            alt="info.fistName"
            className="account-img"
            src="https://773-668-lookup.kjwc.org/themes/default/assets//images/comments_avatar.png"
          />
        </span> */}

        {!(onMobile || onTablet) && 
          <div className="info">
            <span className="name medium">{info && info.firstName}</span>
            <span className="email regular-11pt">{info && info.role}</span>
          </div>
        }
      </div>
    </div>
  );
};
