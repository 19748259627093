import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { SET_ERROR_ALERT, REMOVE_ERROR_ALERT } from '../types/Alert';

export const setErrorAlert = (message: string, alertType?: string): any => (
  dispatch: Dispatch<AnyAction>,
): void => {
  dispatch({
    type: SET_ERROR_ALERT,
    payload: { message, alertType },
  });

  setTimeout(() => dispatch(removeErrorAlert()), 5000);
};

export const removeErrorAlert = (): any => (
  dispatch: Dispatch<AnyAction>,
): void => {
  dispatch({
    type: REMOVE_ERROR_ALERT,
  });
};
