import * as React from 'react';
import { Pharmacy } from '../../services/types/Pharmacy'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as PrescriptionIcon } from '../../../assets/svg/add.svg';
import { ReactComponent as FaceBookIcon } from '../../../assets/svg/facebook-f-brands.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/svg/instagram-brands.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/svg/twitter-brands.svg';
import { Grid } from '@material-ui/core';


interface Props {
  pharmacy: Pharmacy;
}

export const PharmacyInformation: React.FC<Props> = ({
  pharmacy,
}: Props): JSX.Element =>
  <>
    <div className="user-info">
      <div className="contact-header">
        <span>Contact Information</span>
      </div>
    <Grid container>
      <Grid container xs={12}>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">Pharmacy Name</span>
            <span className="info-value"> {pharmacy.name}</span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">Email</span>
            <span className="info-value">{pharmacy.email}</span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">Address</span>
            <span className="info-value">
              {pharmacy.address}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">State</span>
            <span className="info-value">
              {pharmacy.state}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">City</span>
            <span className="info-value">
              {pharmacy.town}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">Zip Code</span>
            <span className="info-value">
              {pharmacy.zipCode}
            </span>
          </div>
        </Grid>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} md={4}>
          <div className="info">
            <span className="label">Phone Number</span>
            <span className="info-value">{pharmacy.phoneNumber}</span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          {pharmacy.faxNumber &&
            <div className="info">
              <span className="label">Fax Number</span>
              <span className="info-value">{pharmacy.faxNumber}</span>
            </div>
          }
        </Grid>
        {pharmacy.webSite &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="label">Web Site</span>
              <span className="info-value">{pharmacy.webSite}</span>
            </div>
          </Grid>
        }
      </Grid>

      {(pharmacy.longitude && pharmacy.latitude) &&
        <Grid container xs={12}>
          <Grid item xs={12} md={6}>
            <div className="info">
              <span className="label">latitude</span>
              <span className="info-value">
                {pharmacy.latitude || ''}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="info">
              <span className="label">longitude</span>
              <span className="info-value">
                {pharmacy.longitude || ''}
              </span>
            </div>
          </Grid>
        </Grid>
      }

      <Grid container xs={12}>
        {pharmacy.hasDelivery &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="info-value" style={{
                display: 'flex'
              }}>
                <LocalShippingIcon style={{
                  marginRight: 20
                }}
                                   />
                                 This pharmacy has Delivery
            </span>
            </div>
          </Grid>
        }
        {pharmacy.hasAutoRefill &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="info-value" style={{
                display: 'flex'
              }}>
                <PrescriptionIcon />  This pharmacy provide Auto Refill
            </span>
            </div>
          </Grid>
        }
          {pharmacy.userInfoIsRequired &&
            <Grid item xs={12} md={4}>
              <div className="info">
                <span className="info-value" style={{
                  display: 'flex'
                }}>
                <InfoOutlinedIcon style={{
                    marginRight: 20
                  }}
                                  />
                                 This pharmacy required ID and Helth Card before submit a new prescription on the mobile app.
            </span>
              </div>
            </Grid>
          }
      </Grid>

      <Grid container xs={12}>
        {pharmacy.facebook &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="info-value" style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                <span className="icon-container"> 
                <FaceBookIcon className="icon" />
                 </span>
                <a href={`https://www.facebook.com/${pharmacy.facebook}`} target="_blanck"> @{pharmacy.facebook} </a>
              </span>
            </div>
          </Grid>
        }
        {pharmacy.instagram &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="info-value" style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                <span className="icon-container"> 
                <InstagramIcon className="icon" />
                </span>
                <a href={`https://www.instagram.com/${pharmacy.instagram}`} target="_blanck"> @{pharmacy.instagram}</a>
              </span>
            </div>
          </Grid>
        }
        {pharmacy.twitter &&
          <Grid item xs={12} md={4}>
            <div className="info">
              <span className="info-value" style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
                <span className="icon-container"> 
                  <TwitterIcon className="icon" />
                </span>
                
                <a href={`https://twitter.com/${pharmacy.twitter}`} target="_blanck">  @{pharmacy.twitter}</a>

              </span>
            </div>
          </Grid>
        }
      </Grid>
    </Grid>
    </div>
  </>
  ;
