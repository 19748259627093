import React, { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { reduxState } from '../services/types/reduxStates';
import { RouteComponentProps } from '@reach/router';
import { useGrantedAccessValidator } from '../hooks/useGrantedAccessValidator';

interface ProtectedRouteProps extends RouteComponentProps {
  component: FunctionComponent<{}> | any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}): JSX.Element => {
  const { isAuthenticated, loading } = useSelector(
    (state: reduxState) => state.auth,
  );

  const hasValidAccess = useGrantedAccessValidator();

  const redirect = (props: any) => (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          from: props.location,
        },
      }}
      />
  );

  return (
    <Route
      {...rest}
      exact
      render={props => {
        if (isAuthenticated && !loading) {
          if (hasValidAccess) {
          return <Component {...props} />;
          } 
            return redirect(props);
        } 
          return redirect(props);
        
      }}
      />
  );
};
