import {
  PatientStates,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAIL,
  PatientsStates,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
} from '../types/Patients';
import { PatientActionType, PatientsActionType } from '../types/actions';

const initialState: PatientStates = {
  loading: true,
  patient: null,
};

export const patientReducer = (
  state = initialState,
  action: PatientActionType,
): PatientStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PATIENT_SUCCESS:
      return {
        loading: false,
        patient: payload,
      };
    case GET_PATIENT_FAIL:
      return {
        loading: false,
        patient: null,
      };
    default:
      return state;
  }
};

const initialStateList: PatientsStates = {
  loading: true,
  patients: null,
};

export const patientsReducer = (
  state = initialStateList,
  action: PatientsActionType,
): PatientsStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PATIENTS_SUCCESS:
      return {
        loading: false,
        patients: payload,
      };
    case GET_PATIENTS_FAIL:
      return {
        loading: false,
        patients: null,
      };
    default:
      return state;
  }
};
