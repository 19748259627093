import * as React from 'react';
import useFormValidation from '../../hooks/useFormValidation';
import { validatePharmacyData } from '../../util/validation';
import { TextField, InputLabel, Input, Select, MenuItem, Grid } from '@material-ui/core';
import { regularAlert } from '../../util/systemAlert';
import { useDispatch } from 'react-redux';
import { PhoneNumberInput, StateInput, TownInput, EmailInput } from '../../layout/App.components';
import { Pharmacy } from '../../services/types/Pharmacy';
import { updatePharmacy } from '../../services/actions/pharmacy';
import { capital_letter } from '../../util/helpers';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {
//       // display: 'flex',
//       // justifyContent: 'space-between',
//     },
//     textField: {
//       // width: '100%',
//     },
//     centerTextField: {
//       // marginLeft: theme.spacing(1),
//       // marginRight: theme.spacing(1),
//       // width: '100%',
//     },
//     firstTextField: {
//        marginRight: theme.spacing(1),
//       // width: '100%',
//     },
//   }),
// );


interface Props {
  pharmacy: Pharmacy;
  submitEditFrom: boolean;
  setSubmitEditFrom: (value: boolean) => void;
  setEditMode: (value: boolean) => void;
}

interface EditStates {
  name: string | any;
  email: string | any;
  address: string | any;
  town: string | any;
  state: string | any;
  zipCode: string | any;
  phoneNumber: string | any;
  faxNumber: string | any;
  longitude: any;
  latitude: any;
  webSite: string;
  hasDelivery: boolean | any;
  hasAutoRefill: boolean | any;
  facebook: string;
  instagram: string;
  twitter: string;
  userInfoIsRequired: boolean | any;
}

export const PharmacyInformationEdit: React.FC<Props> = ({
  pharmacy,
  submitEditFrom,
  setSubmitEditFrom,
  setEditMode
}: Props): JSX.Element => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-underscore-dangle
  const _form = React.createRef<HTMLFormElement>();

  const initialState: EditStates = {
    name: pharmacy.name,
    email: pharmacy.email,
    address: pharmacy.address,
    town: pharmacy.town,
    state: pharmacy.state,
    zipCode: pharmacy.zipCode,
    phoneNumber: pharmacy.phoneNumber,
    faxNumber: pharmacy.faxNumber,
    longitude: pharmacy.longitude,
    latitude: pharmacy.latitude,
    webSite: pharmacy.webSite,
    facebook: pharmacy.facebook,
    instagram: pharmacy.instagram,
    twitter: pharmacy.twitter,
    hasDelivery: pharmacy.hasDelivery ? 'YES' : 'NO',
    hasAutoRefill: pharmacy.hasAutoRefill ? 'YES' : 'NO',
    userInfoIsRequired: pharmacy.userInfoIsRequired ? 'YES' : 'NO',

  };

  const onSubmit = () => {
    if (submitEditFrom) {
      regularAlert({
        callback: () => {
          cancelSubmit();
          setSubmitEditFrom(false);
          return dispatch(
            updatePharmacy(
              {
                id: pharmacy.id,
                name: capital_letter(values.name),
                email: values.email,
                address: values.address,
                town: values.town,
                state: values.state,
                zipCode: values.zipCode,
                phoneNumber: values.phoneNumber,
                faxNumber: values.faxNumber || null,
                longitude: values.longitude || null,
                latitude: values.latitude || null,
                webSite: values.webSite,
                hasDelivery: values.hasDelivery === 'YES',
                hasAutoRefill: values.hasAutoRefill === 'YES',
                facebook: values.facebook || null,
                instagram: values.instagram || null,
                twitter: values.twitter || null,
                userInfoIsRequired: values.userInfoIsRequired === 'YES',
              }
            ),
          );
        },
        successConfirmation: {
          title: 'Updated!',
          text: 'Profile has been Updated Successfully.',
        },
        redirect: () => {
          setSubmitEditFrom(false);
          setEditMode(false);
        },
        callbackIfError: () => cancelSubmit(),
      });
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validatePharmacyData, onSubmit);

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  const submitEditFromCb = React.useCallback(() => {
    if (Object.entries(errors).length !== 0) {
      setSubmitEditFrom(false);
    }
  }, [setSubmitEditFrom, errors]);

  React.useEffect(() => {
    if (submitEditFrom) {
      const cForm: any = _form.current;
      cForm.dispatchEvent(new Event('submit'));
    }
    submitEditFromCb();
  }, [submitEditFrom, _form, submitEditFromCb]);

  return (
    <>
      <div className="user-info">
        <div className="contact-header">
          <span>Contact Information</span>
        </div>
        <form method="POST" id="editForm" onSubmit={handleSubmit} ref={_form}>
          <Grid container spacing={3}>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info">
                <TextField
                  error={errors.name && true}
                  id="name"
                  label={errors.name ? errors.name : 'Pharmacy Name'}
                  className=""
                  name="name"
                  autoComplete="name"
                  margin="normal"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <EmailInput
                  error={errors.email && true}
                  label={errors.email ? errors.email : 'Email'}
                  value={values.email}
                  handleChange={handleChange}
                  />
</div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <TextField
                  error={errors.address && true}
                  id="address"
                  label={errors.address ? errors.address : 'Address'}
                  className=""
                  name="address"
                  autoComplete="address"
                  margin="normal"
                  fullWidth
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info">
                <StateInput
                  value={values.state}
                  error={errors.state && true}
                  label={errors.state ? errors.state : 'State'}
                  handleChange={handleChange}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <TownInput
                  value={values.town}
                  error={errors.town && true}
                  label={errors.town ? errors.town : 'City'}
                  handleChange={handleChange}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <InputLabel error={errors.zipCode && true} htmlFor="zipCode">
                  {errors.zipCode ? errors.zipCode : 'Zip Code'}
                </InputLabel>
                <Input
                  error={errors.zipCode && true}
                  id="zipCode"
                  name="zipCode"
                  value={values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  />
                  </div>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <div className="info">
                <PhoneNumberInput
                  label={errors.phoneNumber ? errors.phoneNumber : 'Phone Number'}
                  error={errors.phoneNumber && true}
                  id="phoneNumber"
                  className=""
                  name="phoneNumber"
                  autoComplete="phoneNumber "
                  fullWidth
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <PhoneNumberInput
                  label="Fax Number"
                  id="faxNumber"
                  className=""
                  name="faxNumber"
                  autoComplete="faxNumber"
                  fullWidth
                  value={values.faxNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
                  </div>
              </Grid>
                <Grid item xs={12} md={4}>
                <div className="info">
                <InputLabel error={errors.webSite && true} htmlFor="webSite">
                  {errors.webSite ? errors.webSite : 'Pharmacy Web Site'}
                </InputLabel>
                <Input
                  error={errors.webSite && true}
                  id="webSite"
                  name="webSite"
                  autoComplete="webSite"
                  value={values.webSite}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  />
                  </div>
                </Grid>
            </Grid>

            <Grid container xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <div className="info">
                <TextField
                  id="latitude"
                  label="Latitude"
                  name="latitude"
                  value={values.latitude || ''}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="info">
                <TextField
                  id="longitude"
                  label="Longitude"
                  name="longitude"
                  value={values.longitude || ''}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                    fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={4}>
                <div className="info">
                <InputLabel htmlFor="hasRx30">has Delivery?</InputLabel>
                <Select
                  value={values.hasDelivery}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'hasDelivery',
                    id: 'hasDelivery',
                  }}
                    style={{
                      marginTop: 27
                    }}>
                  <MenuItem value="NO">No</MenuItem>
                  <MenuItem value="YES">Yes</MenuItem>
                </Select>
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <div className="info">
                <InputLabel htmlFor="hasRx30">Provide Auto Refill?</InputLabel>
                <Select
                  value={values.hasAutoRefill}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'hasAutoRefill',
                    id: 'hasAutoRefill',
                    
                  }}
                    style={{
                      marginTop: 27
                    }}>
                  <MenuItem value="NO">No</MenuItem>
                  <MenuItem value="YES">Yes</MenuItem>
                </Select>
                </div>
                </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                  <InputLabel htmlFor="huserInfoIsRequired">Required user ID and Health card befor submit new prescriptions?</InputLabel>
                  <Select
                    value={values.userInfoIsRequired}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{
                      name: 'userInfoIsRequired',
                      id: 'userInfoIsRequired',
                    }}>
                    <MenuItem value="NO">No</MenuItem>
                    <MenuItem value="YES">Yes</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={4}>
                <div className="info">
                <TextField
                  id="facebook"
                  label="Facebook Profile Name"
                  name="facebook"
                  value={values.facebook || ''}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
                </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <TextField
                  id="instagram"
                    label="Instagram Profile Name"
                  name="instagram"
                  value={values.instagram || ''}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="info">
                <TextField
                  id="twitter"
                    label="Twitter Profile Name"
                  name="twitter"
                  value={values.twitter || ''}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                  </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <div className="info-container">
            
            

           

           
          
           
            

            
            <div className={classes.container}>
              <TextField
                id="facebook"
                label="Facebook"
                name="facebook"
                className={classes.firstTextField}
                value={values.facebook || ''}
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <TextField
                id="instagram"
                label="Instagram"
                name="instagram"
                className={classes.firstTextField}
                value={values.instagram || ''}
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <TextField
                id="twitter"
                label="Twitter"
                name="twitter"
                className={classes.textField}
                value={values.twitter || ''}
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
            </div>
          </div> */}
        </form>
      </div>
    </>
  );
};
