import * as React from 'react';
import { Button, withStyles } from '@material-ui/core';
import './button.scss';

interface Props {
  text: string;
  clases?: [string] | string;
  color?: string;
  isDisabled?: boolean;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
}

interface OutLineStyles {
  border: string;
  backgroundColor: string;
}

export const ThemeButton: React.FC<Props> = ({
  text,
  clases,
  color,
  variant,
  isDisabled = false,
}: Props): JSX.Element => {
  let buttonColor: string;
  let outLine: {} | OutLineStyles;

  if (color === 'primary') {
    buttonColor = variant === 'contained' ? 'btn-default' : '';
    outLine =
      variant === 'contained'
        ? {}
        : {
            border: '1px solid #109cf1',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(16, 156, 241, 0.26)',
            },
          };
  } else if (color === 'error') {
    buttonColor = variant === 'contained' ? 'btn-error' : '';
    outLine =
      variant === 'contained'
        ? {}
        : {
            border: '1px solid #f7685b',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(247, 104, 91, 0.26)',
            },
          };
  } else {
    buttonColor = ' ';
    outLine = {};
  }

  const CustomButton = withStyles(() => ({
    root: {
      ...outLine,
    },
  }))(Button);

  return (
    <>
      <CustomButton
        type="submit"
        variant={variant}
        size="large"
        disabled={isDisabled}
        className={`mt-btn ${clases} ${buttonColor} `}>
        <span className="btn-text">{text}</span>
      </CustomButton>
    </>
  );
};
