import { FunctionComponent } from 'react';
import {
  DashboardPage,
  PageUserProfile,
  PharmacyInfoPage,
  EmployeeListPage,
  EmployeeDetailsPage,
  PrescriptionListPage,
  PrescriptionDetailsPage,
  PatientsListPage,
  PatientDetailsPage,
  RefillListPage,
} from '../pages/App.pages';

interface RoutesType {
  path: string;
  isPrtotected?: boolean;
  isExact?: boolean;
  component: FunctionComponent<{}> | any;
}

export const routes: Array<RoutesType> = [
  {
    path: '/dashboard',
    isPrtotected: true,
    component: DashboardPage,
  },
  {
    path: '/dashboard/profile/',
    isPrtotected: true,
    component: PageUserProfile,
  },
  {
    path: '/dashboard/pharmacy-info',
    isPrtotected: true,
    component: PharmacyInfoPage,
  },
  {
    path: '/dashboard/employee-list',
    isPrtotected: true,
    component: EmployeeListPage,
  },
  {
    path: '/dashboard/prescriptions-list',
    isPrtotected: true,
    component: PrescriptionListPage,
  },
  {
    path: '/dashboard/refills-list',
    isPrtotected: true,
    component: RefillListPage,
  },
  {
    path: '/dashboard/patients-list',
    isPrtotected: true,
    component: PatientsListPage,
  },
  {
    path: '/dashboard/patient-details/:id',
    isPrtotected: true,
    component: PatientDetailsPage,
  },
  {
    path: '/dashboard/employee-details/:id',
    isPrtotected: true,
    component: EmployeeDetailsPage,
  },
  {
    path: '/dashboard/prescription-details/:id',
    isPrtotected: true,
    component: PrescriptionDetailsPage,
  },
];
