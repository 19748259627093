import React from 'react';
import { Menu, Logo } from '../../App.components';
import './side-bar.scss';
import { APP_VERSION } from '../../../../config';

export const SideBar: React.FC = (): JSX.Element => {
  // const [toggle, setToggle] = useState<boolean>(false);
  const toggleAction = '';

  // const changeNavState = (): void => {
  //   setToggle(!toggle);
  // };
  return (
    <div className={`side-bar ${toggleAction}`}>
      <Logo toggle={false} toggleAction={toggleAction} />
      <div className="side-nav-wrap">
        <Menu toggleAction={toggleAction} />
        <div className="" style={{
          textAlign: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}>
          {/* <SideBarToggle changeNavState={changeNavState} toggle={toggle} /> */}
          Version: {APP_VERSION}
        </div>
      </div>
    </div>
  );
};
