import { SearchActionType } from '../types/actions';
import {
  SearchStates,
  SET_USER_SEARCH,
  ERROR_USER_SEARCH,
} from '../types/Search';

const initialState: SearchStates = {
  loading: false,
  list: [],
};

export const searchReducer = (
  state = initialState,
  action: SearchActionType,
): SearchStates => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_SEARCH:
      return {
        ...state,
        loading: false,
        list: payload,
      };
    case ERROR_USER_SEARCH:
      return {
        ...state,
        loading: false,
        list: [],
      };
    default:
      return state;
  }
};
