import React from 'react'
import { PatientRelations } from '../../services/types/Patients'
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { DialogTitle } from '../../layout/App.components';
import { cryptoService } from '../../util/crypto';
import { dateFormat } from '../../util/helpers';
import { ImageModalData, ImageModal } from './ImageModal';
import { ReactComponent as PlanIcon } from '../../../assets/svg/hospital-light.svg';
import { ReactComponent as IDIcon } from '../../../assets/svg/id-card-light.svg';

interface Props {
  data: PatientRelations;
  isOpen: boolean;
  pharmacy: string;
  handleClose: () => void;
}

export const DependantInfoModal: React.FC<Props> = ({
  data,
  isOpen,
  pharmacy,
  handleClose,
}: Props): JSX.Element => {
  const [imageModalData, setImageModalData] = React.useState<ImageModalData | null>(null);
  const [openImage, setOpenImage] = React.useState(true);
  const handleCloseimage = (): void => {
    setOpenImage(false);
  };

  const openImageModal = (imageType: string): void => {
    setImageModalData({
      imageType,
      pharmacy,
      dependent: data.id
    });
    setOpenImage(true);
  }
  return(
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {cryptoService.decrypt(data.firstName)}{' '}
          {cryptoService.decrypt(data.lastName)}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div className="user-info">
                <div className="contact-header">
                  <span>Patient Information</span>
                </div>
                <div className="info-container">
                  <div className="info">
                    <span className="label">Gender</span>
                    <span className="info-value">{data.gender}</span>
                  </div>
                  <div className="info">
                    <span className="label">Date of Birth</span>
                    <span className="info-value">
                      {dateFormat(data.dayOfBirth)}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="user-info">
                <div className="contact-header">
                  <span>Address Information</span>
                </div>
                <div className="info-container">
                  <div className="info">
                    <span className="label">Address</span>
                    <span className="info-value">
                      {data.address}
                    </span>
                  </div>
                  <div className="info-flex">
                    <div className="info">
                      <span className="label">State</span>
                      <span className="info-value">
                        {data.state}
                      </span>
                    </div>
                    <div className="info">
                      <span className="label">City</span>
                      <span className="info-value">
                        {data.town}
                      </span>
                    </div>
                    <div className="info">
                      <span className="label">Zip Code</span>
                      <span className="info-value">
                        {data.zipCode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          
          </Grid>
          <div className="identification-information-container">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className="card" onClick={() => data.healthPlan ? openImageModal('healthPlan') : null} >
                  <div className="icon">
                    <PlanIcon className="mat-icon" />
                  </div>
                  <div className="text">
                    {!data.healthPlan ? 'No Heath Plan Submitted' : 'View Health Plan'}
                  </div>

                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="card" onClick={() => data.personalIdentification ? openImageModal('personalIdentification') : null}>
                  <div className="icon">
                    <IDIcon className="mat-icon" />
                  </div>
                  <div className="text">
                    {!data.personalIdentification ? 'No Identification Submitted' : 'View ID'}
                  </div>

                </div>
              </Grid>
            </Grid>
            {imageModalData && <ImageModal isOpen={openImage} handleClose={handleCloseimage} data={imageModalData} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

