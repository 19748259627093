import React from 'react'
import './notes.scss';
import { NoteItem } from './NoteItem'
import { NoteRelationObject } from '../../../services/types/Note'
import { Card, Grid, CardContent, Grow } from '@material-ui/core'
import { PharmacyRelations } from '../../../services/types/User';
import { AddNoteModal } from './AddNoteModal';
import { sortByDate } from '../../../util/helpers';


interface Props {
  notes: NoteRelationObject[];
  pharmacy: PharmacyRelations;
  addTo: {
    type: 'patient' | 'prescription';
    id: string;
  };
}

export const NotesComponent: React.FC<Props> = ({ notes, pharmacy, addTo}): JSX.Element => {

  const [open, setOpen] = React.useState(false);


  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
    <Card className="contact-info">
      <Grid item xs={12}>
        <div className="card_title">
          <h1 className="card_title_test">Notes</h1>
            <div className="cart_title_action to_the_left" onClick={() => setOpen (true)}>Add Note +</div>
        </div>
      </Grid>
      <CardContent>
        <section className="notes_container">
            {notes && pharmacy ? sortByDate(notes).filter((note: NoteRelationObject) => note.pharmacy.id === pharmacy.id).map((note: NoteRelationObject) => <Grow in={true} key={note.id}><NoteItem  note={note} /></Grow>) : 'No notes at the moment'}
        </section>
      </CardContent>
    </Card>
      <AddNoteModal handleClose={handleClose} isOpen={open} addTo={addTo} pharmacy={pharmacy.id} />
    </>
  )
}
