import { EmployActionType } from '../types/actions';
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  EmployeesStates,
  GET_EMPLOYEES_DETAILS_SUCCESS,
  GET_EMPLOYEES_DETAILS_FAIL,
} from '../types/Employees';

const initialState: EmployeesStates = {
  loading: true,
  pharmacists: [],
  pharmacist: [],
};

export const employeesReducer = (
  state = initialState,
  action: EmployActionType,
): EmployeesStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        pharmacists: payload,
        pharmacist: [],
      };
    case GET_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        pharmacists: [],
      };
    case GET_EMPLOYEES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        pharmacist: payload,
      };
    case GET_EMPLOYEES_DETAILS_FAIL:
      return {
        ...state,
        loading: true,
        pharmacist: [],
      };
    default:
      return state;
  }
};
