export const myStyles = {
  // root: {
  //   boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
  //   borderRadius: '4px',
  //   color: '#707683',
  //   width: '100%',
  // },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  thead: {
    display: 'table-header-group',
    verticalAlign: 'middle',
    borderColor: 'inherit',
  },
  tr: {
    height: '56px',
  },
  thCel: {
    color: 'rgba(51, 77, 99, 0.5)',
    fontWeight: 500,
    fontSize: '14px',
    borderBottomColor: '#EBEFF2',
    textAlign: 'left' as 'left',
    maxWidth: '150px',
  },
  tbody: {
    display: 'table-row-group',
    verticalAlign: 'middle',
    borderColor: 'inherit',
  },
  bodyTr: {
    height: '56px',
    cursor: 'pointer',
    background: 'none',
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
      zIndex: 10,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: '#f2f9ff',
    },
  },
  matCell: {
    borderBottomColor: '#EBEFF2',
    color: '#707683',
    fontweight: 300,
    fontSize: '14px',
    paddingBottom: '1.5384615385rem',
    paddingTop: '1.5384615385rem',
    paddingRight: '1.8461538462rem',
    textAlign: 'left' as 'left',
    maxWidth: '150px',
    alignItems: 'center !important',
  },
  matCheck: {
    borderBottomColor: '#EBEFF2',
    paddingLeft: '24px',
    width: '10px',
  },
  matIcon: {
    cursor: 'pointer',
    fontSize: '16px',
    height: '16px',
    transition: 'color 0.2s ease-in-out',
    maxWidth: '30px',
    paddingLeft: '16px',
    '&:hover': {
      color: '#F7685B',
    },
  },
  selected: {
    alignItems: 'center',
    display: 'flex',
    borderBottom: 'none',
    color: '#192A3E',
    fontWeight: 500,
    paddingBottom: '1.5384615385rem',
    paddingTop: '1.5384615385rem',
    paddingRight: '1.8461538462rem',
    width: '100%',
  },
  myFooter: {
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
  },
};
