/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { makeStyles, Theme, createStyles, Paper, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {
      boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
      borderRadius: '4px',
      color: '#707683',
      width: '100%',
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 15
    },
    search: {
      position: 'relative',
      borderRadius: 15,
      backgroundColor: '#F5F6F8',
      marginRight: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(2, 1, 2, 7),
      transition: theme.transitions.create('width'),
    }
  }),
);

export interface TableSearchOptions {
  list: readonly unknown[];
  keys: any[];
}

interface Props {
  placeholder?: string;
  updateQuery: (value: any) => void;
}

export const TableSearch: React.FC<Props> = ({
  placeholder = 'Search...',
  updateQuery,
}): JSX.Element => {
  const classes = useStyles();
  const [query, setQuery] = React.useState('');

  const onSearch = (event: any) => {
    setQuery(event.currentTarget.value);
    updateQuery(event.currentTarget.value);
  }

  return (
    <Paper className={classes.searchContainer}>
      <div className={classes.search}>
        <div >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={placeholder}
            fullWidth
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={e => onSearch(e)}
            value={query}
            inputProps={{ 'aria-label': 'search' }}
            />
        </div>
      </div>
    </Paper>
  )
}
