import * as React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

interface CityProps {
  value: string,
  label: string,
  error: boolean,
  handleChange: () => void;
}

export const StateInput: React.FC<CityProps> = ({
  value,
  handleChange,
  label,
  error
}: CityProps): JSX.Element => {

  return (
    <>
      <InputLabel error={error} htmlFor="state">{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        fullWidth
        inputProps={{
          name: 'state',
          id: 'state',
        }}>
      
        <MenuItem value={'Puerto Rico'}>Puerto Rico</MenuItem>
    
      </Select>
    </>
  );
}