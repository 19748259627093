import React from 'react'
import { BusinessHours } from '../../services/types/Pharmacy';
import { Card, CardContent } from '@material-ui/core';
import { sortByNumber, getDateString, formatHours } from '../../util/helpers';
import { ThemeButton } from '../../layout/App.components';
// import { useDispatch } from 'react-redux';
// import { addBusinessHours } from '../../services/actions/pharmacy';
import { AddPharmacyBusinessHoursModal } from './AddPharmacyBusinessHoursModal';
import { UpdatePharmacyBusinessHoursModal } from './UpdatePharmacyBusinessHoursModal';

interface Props {
  businessHours: BusinessHours[];
  pharmacy: string;
}

export const PharmacyBusinessHours: React.FC<Props> = ({ businessHours, pharmacy }: Props): JSX.Element => {

  // modalAdd
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleCloseAddModal = (): void => {
    setOpenAdd(false);
  };

  // modalUpdate
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleCloseUpdateModal = (): void => {
    setOpenUpdate(false);
  };


  return (
    <>
      <Card className="contact-info">
        <CardContent>
          {businessHours && businessHours.length ?
            <>
              <div className="user-info">
                <div className="contact-header">
                  <span>Business Hours</span>
                </div>
                <div className="info-container">
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
                    <span style={{ marginRight: 15 }}>Day</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <span style={{ width: 60, display: 'inline-block', textAlign: 'center' }}>
                        Open
                      </span>
                      <span style={{ width: 60, display: 'inline-block', textAlign: 'center' }}>
                        Close
                      </span>
                    </div>
                  </div>
                  {sortByNumber(businessHours).map((dayHour: BusinessHours) =>
                    <div key={dayHour.id} className="hours_container">
                      <span><b>{getDateString(dayHour.day)}: </b></span>
                      <span style={{ marginLeft: 'auto' }}>{dayHour.openTime ? `${formatHours(dayHour.openTime)} -` : 'Close'} {dayHour.closeTime && formatHours(dayHour.closeTime)}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="detail-btn" onClick={(): void => setOpenUpdate(true)} >
                <ThemeButton
                  text="Edit Hours"
                  clases="tc-btn"
                  color="primary"
                  variant="contained"
                  />
              </div>
            </>
            :
            <>
              <div className="user-info">
                <div className="contact-header">
                  <span>Business Hours</span>
                </div>
              </div>
              <div className="detail-btn" onClick={(): void => setOpenAdd(true)}>
                <ThemeButton
                  text="Add Hours"
                  clases="tc-btn"
                  color="primary"
                  variant="contained"
                  />
              </div>
            </>
          }

        </CardContent>
      </Card>
      <AddPharmacyBusinessHoursModal isOpen={openAdd} handleClose={handleCloseAddModal} pharmacy={pharmacy} />
      <UpdatePharmacyBusinessHoursModal isOpen={openUpdate} handleClose={handleCloseUpdateModal} pharmacy={pharmacy} businessHoursData={businessHours} />
    </>
  )
}
