import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const confirmationButtomColor: {
  confirm: string;
  cancel: string;
} = {
  confirm: '#109cf1',
  cancel: '#f7685b',
};

interface AlertOptions {
  title: string;
  text: string;
  confirmButtonText?: string;
}

interface AlertInterface {
  successConfirmation: AlertOptions;
  callback: () => Promise<any>;
  redirect?: () => void;
  callbackIfError?: () => void;
}

interface AlertConfirmation extends AlertOptions, AlertInterface {}

type RegularAlert = AlertInterface;

export const confirmationAlert = (options: AlertConfirmation): void => {
  const {
    title,
    text,
    confirmButtonText,
    callback,
    redirect,
    successConfirmation,
    callbackIfError,
  }: AlertConfirmation = options;

  MySwal.fire({
    title,
    text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: confirmationButtomColor.confirm,
    cancelButtonColor: confirmationButtomColor.cancel,
    confirmButtonText,
  }).then(result => {
    if (result.value) {
      // eslint-disable-next-line no-shadow
      callback().then((result: any) => {
        if (result.action) {
          MySwal.fire(
            successConfirmation.title,
            successConfirmation.text,
            'success',
          ).then(() => redirect && redirect());
        } else {
          MySwal.fire({
            type: 'error',
            title: 'Oops...',
            text: `${result.message}`,
          }).then(() => callbackIfError && callbackIfError());
        }
      });
    }
  });
};

export const regularAlert = (options: RegularAlert): void => {
  const {
    callback,
    successConfirmation,
    redirect,
    callbackIfError,
  }: RegularAlert = options;

  callback().then((result: any) => {
    if (result.action) {
      redirect && redirect();
      MySwal.fire(
        successConfirmation.title,
        successConfirmation.text,
        'success',
      );
    } else {
      redirect && redirect();
      MySwal.fire({
        type: 'error',
        title: 'Oops...',
        text: `${result.message}`,
      }).then(() => callbackIfError && callbackIfError());
    }
  });
};
