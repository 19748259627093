import { makeStyles, Theme, createStyles, lighten, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  onHandleMultipleDelete?: () => void;
  handleMultipleCompleted?: () => void;
  hasCompleteBtn?: boolean;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, onHandleMultipleDelete, handleMultipleCompleted, hasCompleteBtn } = props;

  return (
    <Toolbar>
      {numSelected > 0 && 
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
        }
      {numSelected > 0 &&
      <>
        {hasCompleteBtn && 
          <Tooltip title="Complete">
          <IconButton aria-label="complete" onClick={handleMultipleCompleted}>
              <CheckCircleOutlineIcon style={{
                color: '#2ed47a'
              }}
                                      />
            </IconButton>
          </Tooltip>
        }
        
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onHandleMultipleDelete}>
            <DeleteIcon style={{
              color: '#f7685b'
            }}
                        />
          </IconButton>
        </Tooltip>
      </>
        }
    </Toolbar>
  );
};
