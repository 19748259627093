import { PharmacyActionType } from '../types/actions';
import {
  PharmacyStates,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAIL,
} from '../types/Pharmacy';

const initialState: PharmacyStates = {
  loading: true,
  pharmacy: null,
};

export const pharmacyReducer = (
  state = initialState,
  action: PharmacyActionType,
): PharmacyStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PHARMACIES_SUCCESS:
      return {
        ...state,
        loading: false,
        pharmacy: payload,
      };
    case GET_PHARMACIES_FAIL:
      return {
        ...state,
        loading: false,
        pharmacy: [],
      };
    default:
      return state;
  }
};
