import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

export const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#109cf1',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
