import {
  NotificationsStates,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from '../types/notifications';
import { NotificationActionType } from '../types/actions';

const initialState: NotificationsStates = {
  loading: true,
  notifications: null,
};

export const notificationReducer = (
  state = initialState,
  action: NotificationActionType,
): NotificationsStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: payload,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: true,
        notifications: null,
      };
    default:
      return state;
  }
};
