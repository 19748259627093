import moment from 'moment';

export const sortByDate = (arr: any) =>
  arr.sort(
    (a: any, b: any) =>
      Date.parse(new Date(b.createdAt).toString()) -
      Date.parse(new Date(a.createdAt).toString()),
  );

export const sortByNumber = (arr: any) =>
  arr.sort((a: any, b: any) => a.day - b.day);

export const dateFormat = (date: Date): string =>
  moment(new Date(date)).format('MMM. DD. YYYY');

export const formatHours = (hours: string): string =>
  moment(hours, 'HH:mm:ss').format('LT');

interface WeekDays {
  day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  dayString: string;
}

export const getDateString = (dayNumber: number): string => {
  const weekdays: WeekDays[] = [
    { day: 1, dayString: 'Monday' },
    { day: 2, dayString: 'Tuesday' },
    { day: 3, dayString: 'Wednesday' },
    { day: 4, dayString: 'Thursday' },
    { day: 5, dayString: 'Friday' },
    { day: 6, dayString: 'Saturday' },
    { day: 7, dayString: 'Sunday' },
  ];

  const day = weekdays.find((dayObj: WeekDays) => dayObj.day === dayNumber);
  return day ? day.dayString : '';
};

export const getHoursList = [
  // { military: '00:00:00', twelvesHour: '12:00 AM' },
  // { military: '00:30:00', twelvesHour: '12:30 AM' },
  // { military: '00:45:00', twelvesHour: '12:45 AM' },
  // { military: '01:00:00', twelvesHour: '1:00 AM' },
  // { military: '01:30:00', twelvesHour: '1:30 AM' },
  // { military: '01:45:00', twelvesHour: '1:45 AM' },
  // { military: '02:00:00', twelvesHour: '2:00 AM' },
  // { military: '02:30:00', twelvesHour: '2:30 AM' },
  // { military: '03:00:00', twelvesHour: '3:00 AM' },
  // { military: '03:30:00', twelvesHour: '3:30 AM' },
  // { military: '04:00:00', twelvesHour: '4:00 AM' },
  // { military: '04:30:00', twelvesHour: '4:30 AM' },
  { military: '05:00:00', twelvesHour: '5:00 AM' },
  { military: '05:30:00', twelvesHour: '5:30 AM' },
  { military: '05:45:00', twelvesHour: '5:45 AM' },
  { military: '06:00:00', twelvesHour: '6:00 AM' },
  { military: '06:30:00', twelvesHour: '6:30 AM' },
  { military: '06:45:00', twelvesHour: '6:45 AM' },
  { military: '07:00:00', twelvesHour: '7:00 AM' },
  { military: '07:30:00', twelvesHour: '7:30 AM' },
  { military: '07:45:00', twelvesHour: '7:45 AM' },
  { military: '08:00:00', twelvesHour: '8:00 AM' },
  { military: '08:30:00', twelvesHour: '8:30 AM' },
  { military: '08:45:00', twelvesHour: '8:45 AM' },
  { military: '09:00:00', twelvesHour: '9:00 AM' },
  { military: '09:30:00', twelvesHour: '9:30 AM' },
  { military: '09:45:00', twelvesHour: '9:45 AM' },
  { military: '10:00:00', twelvesHour: '10:00 AM' },
  { military: '10:30:00', twelvesHour: '10:30 AM' },
  { military: '10:45:00', twelvesHour: '10:45 AM' },
  { military: '11:00:00', twelvesHour: '11:00 AM' },
  { military: '11:30:00', twelvesHour: '11:30 AM' },
  { military: '11:45:00', twelvesHour: '11:45 AM' },
  { military: '12:00:00', twelvesHour: '12:00 PM' },
  { military: '12:30:00', twelvesHour: '12:30 PM' },
  { military: '12:45:00', twelvesHour: '12:45 PM' },
  // PM
  { military: '13:00:00', twelvesHour: '1:00 PM' },
  { military: '13:30:00', twelvesHour: '1:30 PM' },
  { military: '13:45:00', twelvesHour: '1:45 PM' },
  { military: '14:00:00', twelvesHour: '2:00 PM' },
  { military: '14:30:00', twelvesHour: '2:30 PM' },
  { military: '14:45:00', twelvesHour: '2:45 PM' },
  { military: '15:00:00', twelvesHour: '3:00 PM' },
  { military: '15:30:00', twelvesHour: '3:30 PM' },
  { military: '15:45:00', twelvesHour: '3:45 PM' },
  { military: '16:00:00', twelvesHour: '4:00 PM' },
  { military: '16:30:00', twelvesHour: '4:30 PM' },
  { military: '16:45:00', twelvesHour: '4:45 PM' },
  { military: '17:00:00', twelvesHour: '5:00 PM' },
  { military: '17:30:00', twelvesHour: '5:30 PM' },
  { military: '17:45:00', twelvesHour: '5:45 PM' },
  { military: '18:00:00', twelvesHour: '6:00 PM' },
  { military: '18:30:00', twelvesHour: '6:30 PM' },
  { military: '18:45:00', twelvesHour: '6:45 PM' },
  { military: '19:00:00', twelvesHour: '7:00 PM' },
  { military: '19:30:00', twelvesHour: '7:30 PM' },
  { military: '19:45:00', twelvesHour: '7:45 PM' },
  { military: '20:00:00', twelvesHour: '8:00 PM' },
  { military: '20:30:00', twelvesHour: '8:30 PM' },
  { military: '20:45:00', twelvesHour: '8:45 PM' },
  { military: '21:00:00', twelvesHour: '9:00 PM' },
  { military: '21:30:00', twelvesHour: '9:30 PM' },
  { military: '21:45:00', twelvesHour: '9:45 PM' },
  { military: '22:00:00', twelvesHour: '10:00 PM' },
  { military: '22:30:00', twelvesHour: '10:30 PM' },
  { military: '22:45:00', twelvesHour: '10:45 PM' },
  { military: '23:00:00', twelvesHour: '11:00 PM' },
  { military: '23:30:00', twelvesHour: '11:30 PM' },
  { military: '23:45:00', twelvesHour: '11:45 PM' },
];

export const capital_letter = (str: string): string =>
  str
    ? str
        .toLocaleLowerCase()
        .replace(/\b[a-zA-Z]/g, (match: string) => match.toUpperCase())
    : str;

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return ''
}
