import React from 'react';
import './patient-details.scss';
import { RouteComponentProps } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import {
  getPatient,
  updatePatientStatus,
} from '../../services/actions/patinet';
import { Loading, NotesComponent } from '../../layout/App.components';
import { cryptoService } from '../../util/crypto';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { Patient, PatientRelations } from '../../services/types/Patients';
import { getPharmacy } from '../../services/actions/pharmacy';
import { confirmationAlert } from '../../util/systemAlert';
import { Link } from 'react-router-dom';
import { DependantInfoModal } from './DependantInfoModal';
import { dateFormat } from '../../util/helpers';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ImageModal, ImageModalData } from './ImageModal';

import { ReactComponent as PlanIcon } from '../../../assets/svg/hospital-light.svg';
import { ReactComponent as IDIcon } from '../../../assets/svg/id-card-light.svg';

export const PatientDetailsPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}> | any,
): JSX.Element => {
  // const [isValidPharmacy, setIsValidPharmacy] = React.useState(false);
  // const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const {
    patient,
    loading,
  }: {
    patient: Patient;
    loading: boolean;
  } = useSelector((state: reduxState) => state.patient);

  const { id } = props.match.params;
  const dispatch = useDispatch();


  const [dependentData, setDependentData] = React.useState<PatientRelations | null>(null)
  const [imageModalData, setImageModalData] = React.useState<ImageModalData | null>(null);

  // modal 
  const [open, setOpen] = React.useState(true);
  const handleClose = (): void => {
    setOpen(false);
  };

  const [openImage, setOpenImage] = React.useState(true);
  const handleCloseimage = (): void => {
    setOpenImage(false);
  };

  const getPatientById = React.useCallback(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    dispatch(getPharmacy());
    getPatientById();
  }, [dispatch, getPatientById]);

  const isTheSameUser = (): boolean => {
    if (patient) {
      return patient.id === id;
    }
    return true;
  }

  const changeStatus = (isActive: boolean): void => {
    const statusText = isActive ? 'Active' : 'Block';
    confirmationAlert({
      title: `Are you sure you want to ${statusText} this Patient?`,
      text: '',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Status Change',
        text: 'Patient status has been change.',
      },
      callback: () =>
        dispatch(
          updatePatientStatus({
            id: patient.id,
            isActive,
          }),
        ),
    });
  };


  const openInfoModal = (data: PatientRelations): void => {
    setDependentData(data);
    setOpen(true);
  }

  const openImageModal = (imageType: string): void => {
    setImageModalData({
      imageType,
      pharmacy: patient.pharmacy.id,
      patient: patient.id,
    });
    setOpenImage(true);
  }

  return (
    <section className="user-details-page patient-details-page">
      {loading || !patient || !isTheSameUser() ?
        <Loading />
        :
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/dashboard/patients-list">Patients List</Link>
                <Typography color="textPrimary">Patient details</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className="contact-info">
                <CardContent>
                  <div className="header">
                    <span className="name">
                      {cryptoService.decrypt(patient.firstName)}{' '}
                      {cryptoService.decrypt(patient.lastName)}
                    </span>

                    <div className="patient_status">
                      <span
                        className={`status_btn active ${!patient.isActive &&
                          'block_active'}`}
                        onClick={() => changeStatus(true)}>
                        {' '}
                          Active{' '}
                      </span>
                      <span
                        className={`status_btn block ${patient.isActive &&
                          'active_block'}`}
                        onClick={() => changeStatus(false)}>
                        {' '}
                          Block{' '}
                      </span>
                    </div>
                  </div>
                  {patient.pharmacy &&
                    <div className="identification-information-container">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <div className="card" onClick={() => patient.healthPlan ? openImageModal('healthPlan') : null} >
                            <div className="icon">
                              <PlanIcon className="mat-icon" />
                            </div>
                            <div className="text">
                              {!patient.healthPlan ? 'No Heath Plan Submitted' : 'View Health Plan'}
                            </div>

                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="card" onClick={() => patient.personalIdentification ? openImageModal('personalIdentification') : null}>
                            <div className="icon">
                              <IDIcon className="mat-icon" />
                            </div>
                            <div className="text">
                              {!patient.personalIdentification ? 'No Identification Submitted' : 'View ID'}
                            </div>

                          </div>
                        </Grid>
                      </Grid>
                    </div>}
                  <div className="user-info">
                    <div className="contact-header">
                      <span>Patient Information</span>
                    </div>
                    <div className="info-container">
                      <div className="info">
                        <span className="label">Gender</span>
                        <span className="info-value">{patient.gender}</span>
                      </div>
                      <div className="info">
                        <span className="label">Date of Birth</span>
                        <span className="info-value">
                          {dateFormat(patient.dayOfBirth)}
                        </span>
                      </div>
                      <div className="info">
                        <span className="label">Active Since</span>
                        <span className="info-value">
                          {dateFormat(patient.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-info">
                    <div className="contact-header">
                      <span>Contact Information</span>
                    </div>
                    <div className="info-container">
                      <div className="info">
                        <span className="label">Email</span>
                        <span className="info-value">{patient.email}</span>
                      </div>
                      <div className="info">
                        <span className="label">Phone Number</span>
                        <span className="info-value">
                          {patient.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-info">
                    <div className="contact-header">
                      <span>Address Information</span>
                    </div>
                    <div className="info-container">
                      <div className="info">
                        <span className="label">Address</span>
                        <span className="info-value">
                          {patient.address}
                        </span>
                      </div>
                      <div className="info-flex">
                        <div className="info">
                          <span className="label">State</span>
                          <span className="info-value">
                            {patient.state}
                          </span>
                        </div>
                        <div className="info">
                          <span className="label">City</span>
                          <span className="info-value">
                            {patient.town}
                          </span>
                        </div>
                        <div className="info">
                          <span className="label">Zip Code</span>
                          <span className="info-value">
                            {patient.zipCode}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <Card className="contact-info">
                    <Grid item xs={12}>
                      <div className="card_title">
                        <h1 className="card_title_test">Dependents</h1>
                      </div>
                    </Grid>
                    <CardContent>
                      <div className="dependents_list_container" >
                        {patient.dependents && patient.dependents.length ?
                          patient.dependents.map(
                            (dependent: PatientRelations) =>
                              <div
                                className="dependents_list "
                                key={dependent.id}
                                onClick={() => openInfoModal(dependent)} >
                                <div style={{ marginRight: 'auto' }}>
                                  <h5 style={{ marginBottom: 5, marginTop: 0 }}>
                                    {cryptoService.decrypt(dependent.firstName)}{' '}
                                    {cryptoService.decrypt(dependent.lastName)}
                                  </h5>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                  {patient.pharmacy &&
                                    <span className="edit-wrap" onClick={() => openInfoModal(dependent)}>
                                      <div className="edit-text">
                                        View
                                      </div>
                                      <div className="icon">
                                        <VisibilityOutlinedIcon />
                                      </div>
                                    </span>
                                  }
                                </div>
                              </div>
                              ,
                          )
                          :
                          <>
                            <h5 style={{ textAlign: 'center', marginTop: 35 }}>
                              No record found
                            </h5>
                          </>
                        }
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {patient.pharmacy && <NotesComponent
                    notes={patient.notes}
                    pharmacy={patient.pharmacy}
                    addTo={{ type: 'patient', id: patient.id }}
                    />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {dependentData && patient.pharmacy && <DependantInfoModal data={dependentData} isOpen={open} handleClose={handleClose} pharmacy={patient.pharmacy.id} />}
          {imageModalData && patient.pharmacy && <ImageModal isOpen={openImage} handleClose={handleCloseimage} data={imageModalData} />}
        </>
      }
    </section>
  );
};
