import './notifications-menu.scss';

import * as React from 'react';

import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  withStyles
} from '@material-ui/core';
import { deleteNotificationAction, getNotifications } from '../../../services/actions/notifications';
import { useDispatch, useSelector } from 'react-redux';

import { MenuProps } from '@material-ui/core/Menu';
import { NotificationRO } from '../../../services/types/notifications';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { getUnReadPrescription } from '../../../services/actions/prescription';
import moment from 'moment';
import { reduxState } from '../../../services/types/reduxStates';
import { sortByDate } from '../../../util/helpers';
import { useHistory } from 'react-router';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '30.5px',
    width: '400px',
    boxShadow: '0 0 16px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
    maxWidth: '400px',
  },
})((props: MenuProps) =>
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
    />
);

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      background: '#fff',
    },
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {},
    },
  },
}))(MenuItem);

export const NotificationsMenu: React.FC = (): JSX.Element => {
  const { pharmacy, loading } = useSelector((state: reduxState) => state.pharmacy);
  const history = useHistory();
  const {
    notifications,
  }: {
    notifications: NotificationRO[] | null;
  } = useSelector((state: reduxState) => state.notifications);

  const dispatch = useDispatch();

  const loadNotifications = React.useCallback(() => {
    if (!loading && pharmacy) {
      dispatch(getNotifications({ pharmacyId: pharmacy.id }));
      dispatch(getUnReadPrescription({ pharmacyID: pharmacy.id }));
    }
  }, [dispatch, loading, pharmacy]);

  React.useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  React.useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      loadNotifications();
    }, 5000);
    return () => clearInterval(interval);
  }, [loadNotifications])

  const hasNewNotifications = (): boolean => {
    const hasNew = notifications && notifications.filter((notification: NotificationRO) => notification.isNew);
    return hasNew ? hasNew.length > 0 : false;
  }

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  const goToPrescription = (id: string, notification: string): void => {
    dispatch(deleteNotificationAction({ id: notification, pharmacyId: pharmacy.id })).then(() => history.push(`/dashboard/prescription-details/${id}`))
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton aria-label="cart" onClick={handleClick}>
        <Badge overlap="rectangular" badgeContent={notifications?.length} color="secondary" invisible={!hasNewNotifications()}>
          <NotificationsNoneOutlinedIcon
            className="mat-icon with-badge"
            fontSize="large"
            />
        </Badge>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {notifications && notifications.length ?
          sortByDate(notifications).slice(0, 5).map((notification: NotificationRO) =>
            <StyledMenuItem key={notification.id} onClick={() => goToPrescription(notification.prescriptionID, notification.id)}>
              <div className="item-content">
                <NotificationsNoneOutlinedIcon
                  className="mat-icon"
                  fontSize="large"
                  />
                <div className="text" >

                  {notification.title}

                  <p className="regular-10pt">
                    {moment(moment(notification.createdAt).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ssZ').fromNow()}
                  </p>
                </div>
              </div>
            </StyledMenuItem>
          )
          :
          <StyledMenuItem>
            <div className="item-content">
              <div className="text center">
                No Notifications
                </div>
            </div>
          </StyledMenuItem>
        }
      </StyledMenu>
    </>
  );
};
