import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { InputLabel, Input } from '@material-ui/core';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
      />
  );
};

interface Props {
  label?: string;
  error?: boolean;
  id?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  fullWidth?: boolean;
  value: string;
  onChange: () => void;
  onBlur: () => void;
}

export const PhoneNumberInput: React.FC<Props> = ({
  label,
  error,
  id,
  className,
  name,
  placeholder,
  autoComplete,
  fullWidth,
  value,
  onChange,
  onBlur,
}: Props): JSX.Element => 
    <>
      <div className="input-container">
        <InputLabel error={error && true} htmlFor={name}>
          {label}
        </InputLabel>
        <Input
          error={error}
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          fullWidth={fullWidth}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          inputComponent={TextMaskCustom as any}
          />
      </div>
    </>
  ;
