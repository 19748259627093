export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export interface NotificationRO {
  id: string;
  isNew: boolean;
  title: string;
  description: string;
  prescriptionID: string;
  createdAt: Date;
}

export interface NotificationsStates {
  loading: boolean;
  notifications: NotificationRO[] | null;
}

export interface GetNotificationAction {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: NotificationRO[];
}

export interface ErrorGetNotificationAction {
  type: typeof GET_NOTIFICATIONS_FAIL;
  payload: null;
}
