import * as React from 'react';
import {
  EmployeeDataTable,
  Loading,
  ThemeButton,
} from '../../layout/App.components';
import { getEmployees } from '../../services/actions/employees';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import './employees-list.scss';
import { CreateEmployeeModal } from './CreateEmployeeModal';
import { reduxState } from '../../services/types/reduxStates';
import { Pharmacist } from '../../services/types/Pharmacist';
import { getPharmacy } from '../../services/actions/pharmacy';
import Fuse from 'fuse.js';
import { TableSearchOptions, TableSearch } from '../../layout/ui/search/TableSearch';


interface Data {
  id: string;
  avatar: string;
  status: boolean;
  role: string;
  username: string;
  phoneNumber: string;
  email: string;
}

const createUserData = (
  id: string,
  avatar: string,
  status: boolean,
  role: string,
  username: string,
  phoneNumber: string,
  email: string,
): Data => ({ id, avatar, status, role, username, phoneNumber, email });

export const EmployeeListPage: React.FC = (): JSX.Element => {
  // const { user } = useSelector((state: reduxState) => state.user);
  const { pharmacists, loading } = useSelector((state: reduxState) => state.employee);
  const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const { user } = useSelector((state: reduxState) => state.user);

  const [pharmacistStatus, setPharmacistStatus] = React.useState<string | unknown>('');
  const [query, updateQuery] = React.useState('');
  const dispatch = useDispatch();
  
  // modal
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };
 
  const dispatchEmployees = React.useCallback(() => {
    if(!pharmacy) {
      dispatch(getPharmacy());
    }
    if (pharmacy) {
      // old trick but work
      if (pharmacists.length <= 0) {
        dispatch(getEmployees(pharmacy.id))
      }
    }
  }, [dispatch, pharmacists, pharmacy])

  React.useEffect(() => {
    dispatchEmployees()
  }, [dispatchEmployees])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPharmacistStatus(event.target.value);
  };

  const filterUserData = (pharmacist: Pharmacist) => {
    switch (pharmacistStatus) {
      case 'Pharmacist':
        return pharmacist.role === 'Pharmacist';
      case 'Administrator':
        return pharmacist.role === 'Administrator';
      case 'Owner':
        return pharmacist.role === 'Owner';
      case 'ChiefPharmacist':
        return pharmacist.role === 'ChiefPharmacist';
      case 'PharmacyTechnician':
        return pharmacist.role === 'PharmacyTechnician';
      default:
        return pharmacist;
    }
  };

  let rows = [];

  if (!loading && pharmacists) {
    rows = pharmacists
      .filter((pharmacist: Pharmacist) => filterUserData(pharmacist))
      .filter((pharmacist: Pharmacist) => user.id !== pharmacist.id)
      .map((pharmacist: Pharmacist) =>
        createUserData(
          pharmacist.id,
          pharmacist.avatar,
          pharmacist.isActive,
          pharmacist.role,
          `${pharmacist.firstName} ${pharmacist.lastName}`,
          pharmacist.phoneNumber,
          pharmacist.email,
        ),
      );
  }

  const tableHeader = [
    'Status',
    'Role',
    'Username',
    'Phone Number',
    'Email',
    'Edit',
  ];

  const options: TableSearchOptions = {
    list: rows,
    keys: [
      'status', 
      'role', 
      'username', 
      'phoneNumber', 
      'email'
    ]
  }

  const { list, keys } = options;
  const fuse = new Fuse(list, { keys });

  const results = fuse.search(query);
  const searchResults = query ? results.map(res => res.item) : rows;

  const tableOption = {
    tableHeader,
    tableData: searchResults,
  };

  return (
    <>
      {loading || !pharmacists ?
        <Loading />
        :
        <>
          <section className="list-page-header">
            <div className="filter">
              <span className="label" title="role" style={
                {
                  marginRight: 10,
                  color: '#334d63'
                }
              }>
                Role:
                </span>
              <Select
                value={pharmacistStatus}
                onChange={handleChange}
                displayEmpty
                name="status">
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                <MenuItem value="Administrator">Administrator</MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
                <MenuItem value="ChiefPharmacist">ChiefPharmacist</MenuItem>
                <MenuItem value="PharmacyTechnician">PharmacyTechnician</MenuItem>
              </Select>
            </div>
            <div className="create-btn" onClick={() => setOpen(true)}>
              <ThemeButton
                text="Add new Employee"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </div>
          </section>
          <TableSearch updateQuery={updateQuery} />
          <EmployeeDataTable tableData={tableOption} />
          <CreateEmployeeModal isOpen={open} handleClose={handleClose} />
        </>
      }
    </>
  );
};
