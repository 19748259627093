import * as CryptoJS from 'crypto-js';

class CryptoService {
  private secretKey = `
      -----BEGIN PUBLIC KEY-----
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgFb44C5oURF9KkMFAp+k/VydWOZK
    /XSQVRVoCP2a0YLnhhrdvB76W9HRg5ScOOnkCpkWpaERw6OvI0mCAR4zRI//Vjee
    RGUkynmYK0wM2+018GrPF7uxonrhvIteDdtKUJ0xvvdfxWMGqVjLCSH6LP8zFpPC
    41Y09iax9tlOrGdvAgMBAAE=
    -----END PUBLIC KEY-----
    `;

  encrypt(value: string): string {
    if (!value) {
      throw Error('Please Privide a value');
    }
    const ciphertext = CryptoJS.AES.encrypt(value, this.secretKey).toString();
    return ciphertext;
  }

  decrypt(value: string): string {
    if (!value) {
      throw Error('Please Privide a value');
    }

    const bytes = CryptoJS.AES.decrypt(value, this.secretKey);

    return bytes.toString(CryptoJS.enc.Utf8);
  }
}

export const cryptoService = new CryptoService();
