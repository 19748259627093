import { produce } from 'immer';
import * as React from 'react';
import { BusinessHours, BusinessHoursRTO } from '../../services/types/Pharmacy';
import { regularAlert } from '../../util/systemAlert';
import { updateBusinessHours } from '../../services/actions/pharmacy';
import { DialogTitle } from '../../layout/App.components';
import { Dialog, DialogContent } from '@material-ui/core';
import { BusinessHoursForm } from './BusinessHoursForm';
import { useDispatch } from 'react-redux';



interface Props {
  isOpen: boolean;
  handleClose: () => void;
  pharmacy: string;
  businessHoursData:  BusinessHours[];
}

export const UpdatePharmacyBusinessHoursModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  pharmacy,
  businessHoursData,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const initalState: BusinessHoursRTO[] = businessHoursData;

const [loading, setloading] = React.useState(false);
  const [businessHoursState, setBusinessHoursState] = React.useState<BusinessHoursRTO[]>(initalState);

  React.useEffect(() => {
    setBusinessHoursState(businessHoursData)
  }, [businessHoursData]);

  const businessHoursSubmit = (_event: any): void => {
    _event.preventDefault()
    regularAlert({
      callback: () => {
        setloading(true);
        return dispatch(
          updateBusinessHours({
            businessHours: businessHoursState,
            pharmacy
          })
        )
      },
      successConfirmation: {
        title: 'Saved!',
        text: 'Business Hours has added Successfully.',
      },
      redirect: () => {
        setloading(false);
        handleClose();
      }
    });
  }


  const handleChange = (e: React.ChangeEvent<{ value: unknown }>, index: number, hourtime: 'openTime' | 'closeTime'): void => {
    const time: string | null = e.target.value === 'Close' ? null : e.target.value as string;
    setBusinessHoursState(currentState =>
      produce(currentState, hour => {
        if (hourtime === 'openTime') {
          hour[index].openTime = time;
        } else {
          hour[index].closeTime = time;
        }
      })
    );
  }

  // const h = businessHours.map((b: BusinessHours) => ({
  //   day: b.day,
  //   openTime: b.openTime,
  //   closeTime: b.closeTime,
  // }));

  // console.log([...h])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Business Hours
        </DialogTitle>
      <DialogContent dividers>
        <BusinessHoursForm
          businessHoursState={businessHoursState}
          buttonText={!loading ? 'Save Hours' : 'Processing....'}
          handleChange={handleChange}
          onSubmit={businessHoursSubmit}
          loading={loading}
          />
      </DialogContent>
    </Dialog>
  )
}
