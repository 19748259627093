import * as React from 'react';
import './error-page.scss';
import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100vh',
    },
    container: {
      padding: theme.spacing(5, 3),
      maxWidth: '90%',
      textAlign: 'right',
    },
    h1: {
      fontSize: '15vh',
    },
    h4: {
      marginTop: '-50px',
      fontSize: '5vh',
    }
  }),
);

interface IErrorPage {
  description: {
    errorCode: string,
    message:  string
  }
}

export const ErrorPage: React.FC<IErrorPage> = ({ description }: IErrorPage): JSX.Element => {

  const { errorCode, message } = description;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h1 className={classes.h1}>{errorCode}</h1>
        <h4 className={classes.h4}>{message}</h4>
      </div>
    </div>
  )
}
