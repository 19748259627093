import * as React from 'react';
import {
  Loading,
  PrescriptionsDataTable,
  TableSearch,
} from '../../layout/App.components';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, Grid } from '@material-ui/core';
import './prescriptions-list.scss';
import { reduxState } from '../../services/types/reduxStates';
import { getPrescriptions, getUnReadPrescription } from '../../services/actions/prescription';
import { Prescription } from '../../services/types/Prescription';
import { cryptoService } from '../../util/crypto';
import { getPharmacy } from '../../services/actions/pharmacy';
import { sortByDate } from '../../util/helpers';
import { TableSearchOptions } from '../../layout/ui/search/TableSearch';
import Fuse from 'fuse.js';


interface Data {
  id: string;
  status: string;
  patientName: string | undefined;
  patinetId: string | undefined;
  type: string;
  date: Date;
  phoneNumber: string | undefined;
  image?: string | undefined;
  hasBeenRead?: boolean;
}

const createUserData = (
  id: string,
  status: string,
  patientName: string | undefined,
  patinetId: string | undefined,
  type: string,
  date: Date,
  phoneNumber: string | undefined,
  image: string | undefined,
  hasBeenRead: boolean
): Data => ({ id, status, patientName, patinetId, type, date, phoneNumber, image, hasBeenRead });

export const PrescriptionListPage: React.FC = (): JSX.Element => {
  // const classes = useStyles();
  const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const { prescriptions, loading } = useSelector((state: reduxState) => state.prescriptions);
  
  const [prescriptionStatus, setprescriptionStatus] = React.useState<string | unknown>('');
  const [query, updateQuery] = React.useState('');

  const dispatch = useDispatch();


  const dispatchEmployees = React.useCallback(() => {
    if (!pharmacy) {
      dispatch(getPharmacy());
    }
    if (pharmacy) {
      dispatch(getPrescriptions(pharmacy.id))
      dispatch(getUnReadPrescription({pharmacyID: pharmacy.id}));
    }
   
  }, [dispatch, pharmacy])


  React.useEffect(() => {
    dispatchEmployees();
  }, [dispatchEmployees, dispatch]);

  React.useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      dispatchEmployees();
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatchEmployees, dispatch])


  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setprescriptionStatus(event.target.value);
  };

  const filterUserData = (prescription: Prescription) => {
    switch (prescriptionStatus) {
      case 'Completed':
        return prescription.status === 'completed';
      case 'Pending':
        return prescription.status === 'pending';
      case 'Denied':
        return prescription.status === 'denied';
      default:
        return prescription.type === 'Prescription';
    }

  };


  let rows: any[] = [];

  if (!loading && prescriptions) {
    rows = sortByDate(prescriptions)
      .filter((prescription: Prescription) => prescription.type === 'Prescription')
      .filter((prescription: Prescription) => filterUserData(prescription))
      .map((prescription: Prescription) => {
        const patientName = `${cryptoService.decrypt(prescription.dependent ? prescription.dependent.firstName : prescription.patient.firstName)} ${cryptoService.decrypt(prescription.dependent ? prescription.dependent.lastName : prescription.patient.lastName)}`;
        return createUserData(
          prescription.id,
          prescription.status,
          patientName,
          prescription.patient.id,
          prescription.type,
          prescription.createdAt,
          prescription.patient.phoneNumber,
          prescription.photo,
          prescription.hasBeenRead
        );
      });
  }

  const tableHeader = [
    'Status',
    'Patient Name',
    'Type',
    'Date',
    'Hour',
    'Patient Phone Number',
    'Actions' 
  ];


  const options: TableSearchOptions = {
    list: rows,
    keys: [
      'status',
      'patientName',
      'type',
      'phoneNumber'
    ]
  }

  const { list, keys } = options;
  const fuse = new Fuse(list, { keys });

  const results = fuse.search(query);
  const searchResults = query ? results.map(res => res.item) : rows;


  const tableOption = {
    tableHeader,
    tableData: searchResults,
  };

  return (
    <div>
      {loading || !prescriptions ?
        <Loading />
        :
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <section className="list-page-header">
                <div className="filter">
                  <span className="label" title="role" style={
                    {
                      marginRight: 10,
                      color: '#334d63'
                    }
                  }>
                    Filters :
                </span>
                  <Select
                    value={prescriptionStatus}
                    onChange={handleChange}
                    displayEmpty
                    name="status"
                    inputProps={{
                      name: 'status',
                      id: 'status',
                    }}>

                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Denied">Denied</MenuItem>
                  </Select>
                </div>
                {/* <div className="create-btn" >
              <ThemeButton
                text="Download Report"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </div> */}

              </section>
            </Grid>
            <Grid item xs={12}>
              <TableSearch updateQuery={updateQuery} />
              <PrescriptionsDataTable tableData={tableOption} />
            </Grid>
          </Grid>
        </>
      }
    </div>
  );
};
