import * as React from 'react';
import {
  Dialog,
  TextField,
  DialogContent,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import useFormValidation from '../../hooks/useFormValidation';
import { validateUserData } from '../../util/validation';
import {
  ThemeButton,
  PhoneNumberInput,
  DialogTitle,
  EmailInput,
} from '../../layout/App.components';
import { updateEmployees } from '../../services/actions/employees';
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { Pharmacist } from '../../services/types/Pharmacist';

interface EditModalProps {
  isOpen: boolean;
  user: Pharmacist;
  phamacy: string;
  handleClose: () => void;
  id: string;
}

interface EditModalStates {
  firstName: string | any;
  lastName: string | any;
  email: string | any;
  phoneNumber: string | any;
  role: string | any;
}

export const EmployeeEditModal: React.FC<EditModalProps> = ({
  isOpen,
  handleClose,
  user,
  phamacy
}: EditModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const initialState: EditModalStates = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    role: user.role,
  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();
        return dispatch(
          updateEmployees({
            id: user.id, 
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            role: values.role,
          }, phamacy),
        );
      },
      successConfirmation: {
        title: 'Updated!',
        text: 'Employee has been Updated Successfully.',
      },
      redirect: () => handleClose(),
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateUserData, onSubmit);

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit {user.firstName} {user.lastName}
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
            <form onSubmit={handleSubmit} method="POST">
              <TextField
                error={errors.firstName && true}
                id="firstName"
                label={errors.firstName ? errors.firstName : 'First Name'}
                className=""
                name="firstName"
                autoComplete="firstName"
                margin="normal"
                fullWidth
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <TextField
                error={errors.lastName && true}
                id="lastName"
                label={errors.lastName ? errors.lastName : 'Last Name'}
                className=""
                name="lastName"
                autoComplete="lastName"
                margin="normal"
                fullWidth
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              
              <EmailInput
                error={errors.email && true}
                label={errors.email ? errors.email : 'Email'}
                value={values.email}
                handleChange={handleChange}
                />    

              <PhoneNumberInput
                label={errors.phoneNumber ? errors.phoneNumber : 'Phone Number'}
                error={errors.phoneNumber && true}
                id="phoneNumber"
                className=""
                name="phoneNumber"
                autoComplete="phoneNumber "
                fullWidth
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                />

              <div className="input-container">
                <InputLabel htmlFor="Role">
                  {errors.role ? errors.role : 'Role'}
                </InputLabel>
                <Select
                  value={values.role}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'role',
                    id: 'role',
                  }}>
                  <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                  <MenuItem value="Administrator">Administrator</MenuItem>
                  <MenuItem value="Owner">Owner</MenuItem>
                  <MenuItem value="ChiefPharmacist">ChiefPharmacist</MenuItem>
                  <MenuItem value="PharmacyTechnician">PharmacyTechnician</MenuItem>
                </Select>
              </div>
              <ThemeButton
                isDisabled={isSubmitting}
                text="Save Changes"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
