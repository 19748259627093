/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { uploadShopper } from '../../services/actions/pharmacy';
import { Pharmacy } from '../../services/types/Pharmacy';
import { Card, CardContent } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ShopperImageModal } from './ShopperImageModal';

interface Props {
  pharmacy: Pharmacy
}

export const PharmacyShopper: React.FC<Props> = ({ pharmacy }: Props): JSX.Element => {
  // modal
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  
  const acceptedTypes: string[] = [
    'application/pdf',

  ];

  const isValidFileType = (fileType: string): boolean => acceptedTypes.includes(fileType);
  const getFileExtension = (filename: string) => filename.split('.').pop();


  const handleUploadClick = (e: any) => {
    e.preventDefault()

    if (typeof e.target.files[0] !== 'undefined') {


      if (!isValidFileType(e.target.files[0].type)) {
        alert('Only PDF are allowed.');
        return;
      }

      const fileName = `${pharmacy.id}-shopper.${getFileExtension(e.target.files[0].name)}`;
      const file = new File([e.target.files[0]], fileName);


      const formData = new FormData()
      formData.append('file', file, fileName);
      formData.append('id', pharmacy.id);

      regularAlert({
        callback: () => dispatch(uploadShopper(formData)),
        successConfirmation: {
          title: 'Updated!',
          text: 'Profile Shopper has been Updated Successfully.',
        }
      });
    }

  };
  return (
    <>
      <Card className="contact-info" style={{
        marginTop: 20
      }}>
        <CardContent>
          <div className="user-info">
            <div className="contact-header">
              <span>Pharmacy Shopper</span>
            </div>

            <div style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%'
            }} className="shopper_container">
              <div style={{ marginRight: 'auto' }}>
                {pharmacy.shopper ?
                  <span className="link" onClick={() => setOpen(true)}>
                    View Shopper
                  </span>
                  : 'does not have shopper'}
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <input
                  accept="application/pdf"
                  className="input"
                  id="shopper"
                  multiple
                  type="file"
                  onChange={handleUploadClick}
                  />

                <label htmlFor="shopper" className="edit-btn">
                  <CloudUploadIcon />
                </label>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {open && <ShopperImageModal shopper={pharmacy.shopper} handleClose={handleClose} isOpen={open} />}
      
    </>
  )
}
