import {
  DELETE_EMPLOYEES_DETAILS_FAIL,
  DELETE_EMPLOYEES_DETAILS_SUCCESS,
  DELETE_MULTIPLE_EMPLOYEES_ERROR,
  DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_SUCCESS,
  STATUS_EMPLOYEES_DETAILS_FAIL,
  STATUS_EMPLOYEES_DETAILS_SUCCESS,
  UPDATE_EMPLOYEES_DETAILS_FAIL,
  UPDATE_EMPLOYEES_DETAILS_SUCCESS,
} from '../types/Employees';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { SET_USER_SEARCH } from '../types/Search';
import axios from 'axios';
import { loadUser } from './auth';

export const getEmployees = (pharmacyID: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ pharmacyID });

  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacists`,
      body,
      config,
    );

    dispatch(loadUser());
    dispatch({
      type: GET_EMPLOYEES_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SET_USER_SEARCH,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEES_FAIL,
    });

    throw error;
  }
};

// export const getEmployeesDetails = (id: string): any => async (
//   dispatch: Dispatch<AnyAction>,
// ): Promise<void> => {
//   try {
//     const response = await axios.get(`${API_HOST}/ontime_admin/users/${id}`);

//     dispatch({
//       type: GET_EMPLOYEES_DETAILS_SUCCESS,
//       payload: response.data,
//     });

//     dispatch(loadUser());
//   } catch (error) {
//     dispatch({
//       type: GET_EMPLOYEES_DETAILS_FAIL,
//     });
//   }
// };

export const deleteEmployees = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.delete(
      `${API_HOST}/pharmacy_admin/pharmacists/${id}`,
    );

    dispatch({
      type: DELETE_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: DELETE_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message: error.response.data.message,
    };
  }
};

export const updateEmployees = (data: any, phamacy: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/pharmacists/update/`,
      body,
      config,
    );

    dispatch({
      type: UPDATE_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });
    dispatch(getEmployees(phamacy));
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: UPDATE_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message: error.response.data.message,
    };
  }
};

export const statusEmployees = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  console.log(body);
  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/pharmacists/status`,
      body,
      config,
    );

    dispatch({
      type: STATUS_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });

    dispatch(getEmployees(data.pharmacyID));
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: STATUS_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message: error.response.data.message,
    };
  }
};

export const createEmployees = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacists/create`,
      body,
      config,
    );

    dispatch(getEmployees(data.pharmacy));
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data.message,
    };
  }
};

export const deleteMultipleEmployees = (
  ids: string[],
  pharmacyID: string,
): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ids,
    },
  };

  try {
    const response = await axios.delete(
      `${API_HOST}/pharmacy_admin/pharmacists/delete/multiple`,
      config,
    );

    dispatch({
      type: DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
      payload: response.data,
    });

    dispatch(getEmployees(pharmacyID));
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    // console.log(error.response);
    dispatch({
      type: DELETE_MULTIPLE_EMPLOYEES_ERROR,
    });
    return {
      action: false,
      message: error.response.data.message,
    };
  }
};
