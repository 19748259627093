import * as React from 'react';
// import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
// import LocalPharmacyOutlinedIcon from '@material-ui/icons/LocalPharmacyOutlined';
import './pharmacy-top-menu.scss';
import {
  // makeStyles,
  // Theme,
  // createStyles,
  withStyles,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';
import { PharmacyRelations } from '../../../services/types/User';
import { useDispatch } from 'react-redux';
import { loadNewPharmacy } from '../../../services/actions/pharmacy';
import { ReactComponent as MulterIcon } from './mortar-icon.svg';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     margin: {
//       margin: theme.spacing(2),
//     },
//   }),
// );
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '30.5px',
    width: '300px',
    boxShadow: '0 0 16px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
    maxWidth: '300px',
  },
})((props: MenuProps) =>
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
    />
);

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      background: '#F2F9FF',
    },
    '&.active' : {
      background: '#F2F9FF',
    },
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {},
    },
  },
}))(MenuItem);

interface Props {
  pharmacies: PharmacyRelations[];
}

export const PharmacyTopMenu: React.FC<Props> = ({ pharmacies }): JSX.Element => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const activePharmacy = localStorage.pharmacy;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const loadPharmacy = (id: string): void => {
    dispatch(loadNewPharmacy(id));
    window.location.replace('/dashboard');
  }

  return (
    <>
      {pharmacies.length > 1 &&
        <>
        <IconButton aria-label="cart" onClick={handleClick} title="Pharmacies List">
          <MulterIcon className="mat-icon" />
        </IconButton>
       
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted={true}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {pharmacies.map((pharmacy: PharmacyRelations) => 
              <StyledMenuItem 
              key={pharmacy.id} 
              onClick={() => loadPharmacy(pharmacy.directoryId)} 
              className={activePharmacy === pharmacy.directoryId ? 'active' : ''}>
                <div className="item-content">
                  <MulterIcon className="mat-icon" />
                  <div className="text-pharma">
                    <span className="normal link-pharma">
                      {pharmacy.name}
                  </span>
           
                  </div>
                </div>
              </StyledMenuItem>
            )}
          </StyledMenu>
        </>
      }

    </>
  );
};
