// @ts-nocheck
import './data-table.scss';

import * as React from 'react';

import {
  EnhancedTableHead,
  TablePaginationActions,
} from '../../App.components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { myStyles } from './tableStyles';

const useStyles = makeStyles(() => createStyles({
  ...myStyles,
  root: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    color: '#707683',
    width: '100%',
    overflowX: 'auto',
  },
}));

interface DataTableProps {
  tableData: any;
}

export const PatientsDataTable: React.FC<DataTableProps> = (
  props: DataTableProps | any,
): JSX.Element => {
  const { tableHeader, tableData } = props.tableData;
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
  const numberFooter = tableHeader.length + 1;


  const handleSelectAllClick = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (_event.target.checked) {
      const newSelecteds = tableData.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRowsPerPage(parseInt(_event.target.value, 10));
    setPage(0);
  };

 

  return (
    <Paper className={classes.root}>
      <TableContainer>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={tableData.length}
          tabaleHeader={tableHeader}
          isActiveDelete={false}
          />
        <TableBody className={classes.tbody}>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              (row: any): JSX.Element => 
                  <TableRow
                    key={row.id}
                    className={classes.bodyTr}>

                    <TableCell className={classes.matCell} align="right">
                      <span
                        className={`ont-badge ${
                          row.status ? 'active' : 'archived'
                          }`}>
                        {' '}
                        {row.status ? 'Active' : 'Archived'}
                      </span>
                    </TableCell>
                 
                    <TableCell className={classes.matCell}>
                      <div className="user-wrap">
                    
                      <Link to={`/dashboard/patient-details/${row.id}/`} className="edit-wrap">
                          <span className="name dot-wrap" title={row.username}>
                            {' '}{row.username}{' '}
                          </span>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span title={row.email} className="dot-wrap">
                        {row.email}{' '}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <Link
                        to={`/dashboard/patient-details/${row.id}/`}
                        className="edit-wrap">
                        {/* <div className="edit-text">
                          View
                        </div> */}
                        <div className="icon">
                        <VisibilityOutlinedIcon />
                        </div>
                      </Link>
                    </TableCell>
                  </TableRow>
                ,
            )}
          {tableData.length === 0 && 
            <TableRow style={{ height: 48 }}>
              <TableCell colSpan={5} style={{ textAlign: 'center', fontSize: '24px' }}>
                Sorry, no record found
                </TableCell>
            </TableRow>
          }
          {emptyRows > 0 && 
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={numberFooter}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              />
          </TableRow>
        </TableFooter>
      </Table>
      </TableContainer>
    </Paper>
  );
};
