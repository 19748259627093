export const SET_ERROR_ALERT = 'SET_ERROR_ALERT';
export const REMOVE_ERROR_ALERT = 'REMOVE_ERROR_ALERT';

export interface ErrorAlertStates {
  message?: string;
  alertType?: string;
}

export interface SetErrorAlert {
  type: typeof SET_ERROR_ALERT;
  payload: ErrorAlertStates;
}

export interface RemoveErrorAlert {
  type: typeof REMOVE_ERROR_ALERT;
  payload: ErrorAlertStates;
}
