import { Pharmacy } from './Pharmacy';
import { PatientRelations } from './Patients';
import { Pharmacist } from './Pharmacist';
import { NoteRelationObject } from './Note';

export const GET_PRESCRIPTIONS_SUCCESS = 'GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_FAIL = 'GET_PRESCRIPTIONS_FAIL';

export const GET_PRESCRIPTION_SUCCESS = 'GET_PRESCRIPTION_SUCCESS';
export const GET_PRESCRIPTION_FAIL = 'GET_PRESCRIPTION_FAIL';

export const GET_UNREAD_PRESCRIPTIONS_SUCCESS =
  'GET_UNREAD_PRESCRIPTIONS_SUCCESS';
export const GET_UNREAD_PRESCRIPTIONS_FAIL = 'GET_UNREAD_PRESCRIPTIONS_FAIL';

export type Code = { numbers: string[] };

export interface Prescription {
  id: string;
  status: string;
  type: string;
  photo: string;
  code: Code;
  attachedNote: string;
  isDelivery: boolean;
  hasBeenRead: boolean;
  deliveryMethod: DeliveryMethod;
  starting: Date;
  ending: Date;
  createdAt: Date;
  udatedAt: Date;
  patient: PatientRelations;
  dependent?: PatientRelations;
  pharmacy: Pharmacy;
  pharmacist?: Pharmacist;
  notes: NoteRelationObject[];
}

export interface UnReadPrescription {
  id: string;
  prescriptionId: string;
  prescriptionType: string;
  createdAt: Date;
  udatedAt?: Date;
  pharmacy?: Pharmacy;
}

export interface UnReadPrescriptionRelation {
  id: string;
  prescriptionId: string;
  prescriptionType: string;
  createdAt: Date;
  udatedAt?: Date;
}

export interface PrescriptionStates {
  loading: boolean;
  prescriptions: Prescription[] | null;
}

export interface PrescriptionDataStates {
  loading: boolean;
  prescription: Prescription | null;
}

export interface UnReadPrescriptionsStates {
  loading: boolean;
  unReadPrescriptions: UnReadPrescription[] | null;
}

export interface GetPrescriptionAction {
  type: typeof GET_PRESCRIPTIONS_SUCCESS;
  payload: Prescription[];
}

export interface ErrorGetPrescriptionAction {
  type: typeof GET_PRESCRIPTIONS_FAIL;
  payload: null;
}

export interface GetPrescriptionDataAction {
  type: typeof GET_PRESCRIPTION_SUCCESS;
  payload: Prescription;
}

export interface ErrorGetPrescriptionDataAction {
  type: typeof GET_PRESCRIPTION_FAIL;
  payload: null;
}

export interface DeliveryMethod {
  refill_rx_integer: number;
  ontime_api_integer: number;
  method: string;
}

export interface GetUnReadPrescriptionAction {
  type: typeof GET_UNREAD_PRESCRIPTIONS_SUCCESS;
  payload: UnReadPrescription[];
}

export interface ErrorGetUnReadPrescriptionAction {
  type: typeof GET_UNREAD_PRESCRIPTIONS_FAIL;
  payload: null;
}
