export const isValidEmail = (email: string): boolean =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isNumbersOnly = (values: string): boolean => {
  const isValid: object = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const char of values) {
    if (!/^[0-9]$/.test(char)) {
      isValid[char] = false;
    }
  }
  return !Object.keys(isValid).length;
};

export const validateAuth = (values: any, withPassword = true) => {
  const errors: { phoneNumber: string; password: string } | any = {};
  // Email Errors
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = 'Phone number must be 10 characters';
  }

  // Password Errors
  if (withPassword) {
    if (!values.password) {
      errors.password = 'Required Password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }
  }
  return errors;
};

export const validateUserData = (values: any) => {
  const errors:
    | {
        firstName: string | any;
        lastName: string | any;
        email: string | any;
        phoneNumber: string | any;
        role: string | any;
      }
    | any = {};

  if (!values.email) {
    errors.email = 'Required Email';
  } else if (isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.firstName) {
    errors.firstName = 'Required First Name';
  }

  if (!values.lastName) {
    errors.lastName = 'Required last Name';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  }

  if (!values.role) {
    errors.role = 'Required Role';
  }
  return errors;
};

export const validateEditeUserData = (values: any) => {
  const errors:
    | {
        firstName: string | any;
        lastName: string | any;
        email: string | any;
        phoneNumber: string | any;
        role: string | any;
      }
    | any = {};

  if (!values.email) {
    errors.email = 'Required Email';
  } else if (isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.firstName) {
    errors.firstName = 'Required First Name';
  }

  if (!values.lastName) {
    errors.lastName = 'Required last Name';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  }
  return errors;
};

export const validatePharmacyData = (values: any) => {
  const errors:
    | {
        name: string | any;
        address: string | any;
        email: string | any;
        phoneNumber: string | any;
        zipCode: string | any;
        state: string | any;
        town: string | any;
      }
    | any = {};

  if (!values.email) {
    errors.email = 'Required Email';
  } else if (isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.name) {
    errors.name = 'Required Pharmacy Name';
  }

  if (!values.address) {
    errors.address = 'Required Pharmacy Address';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  }

  if (!values.state) {
    errors.state = 'Required State';
  }

  if (!values.zipCode) {
    errors.zipCode = 'Required Zip Code';
  } else if (!isNumbersOnly(values.zipCode)) {
    errors.zipCode = 'Invalid characters';
  } else if (values.zipCode.length < 5 || values.zipCode.length > 5) {
    errors.zipCode = 'Maximum length is 5';
  }

  if (!values.town) {
    errors.town = 'Required Town';
  }

  return errors;
};

export const validateChangePass = (values: any) => {
  const errors:
    | {
        currentPassword: string;
        newPassword: string;
        repeteNewPassword: string;
      }
    | any = {};

  // currentPassword Errors
  if (!values.currentPassword) {
    errors.currentPassword = 'Required Current Password';
  } else if (values.currentPassword.length < 8) {
    errors.currentPassword = 'Password must be at least 8 characters';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required New Password';
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Password must be at least 8 characters';
  }

  if (!values.repeatNewPassword) {
    errors.repeatNewPassword = 'Please Confirm Password';
  } else if (values.repeatNewPassword !== values.newPassword) {
    errors.repeatNewPassword =
      'Your new password and confirmation password do not match';
  }

  return errors;
};

export const validateNoteData = (values: any) => {
  const errors:
    | {
        body: string | any;
      }
    | any = {};

  if (!values.body) {
    errors.body = 'Required Note Body';
  }
  return errors;
};
