import * as React from 'react';
import {CircleLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';
import './loading.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '78vh',
    },
    container: {
      padding: theme.spacing(5, 3),
      maxWidth: '100%',
      // boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
      textAlign: 'center',
      fontSize: 18
      
    },
    loading: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 10,
      marginTop: 0,
      width: 50,
    },
    textField: {},
  }),
);

export const Loading: React.FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.loading}>
          <CircleLoader size={50} color="#5FC9F8" />
        </div>
        <p className="loading">Loading data, please wait</p>
      </div>
    </div>
  );
};
