import { patientReducer, patientsReducer } from './reducers/patient';
import {
  prescriptionDetailsReducer,
  prescriptionReducer,
  unReadPrescriptionsReducer,
} from './reducers/prescription';

import { alertReducer } from './reducers/alert';
import { authReducer } from './reducers/auth';
import { combineReducers } from 'redux';
import { employeesReducer } from './reducers/employees';
import { notificationReducer } from './reducers/notifications';
import { pharmacyReducer } from './reducers/pharmacy';
import { reportsReducer } from './reducers/reports';
import { searchReducer } from './reducers/search';
import { userReducer } from './reducers/user';

export const rootReducer: any = combineReducers({
  auth: authReducer,
  user: userReducer,
  employee: employeesReducer,
  pharmacy: pharmacyReducer,
  alert: alertReducer,
  search: searchReducer,
  prescriptions: prescriptionReducer,
  prescription: prescriptionDetailsReducer,
  patient: patientReducer,
  patients: patientsReducer,
  notifications: notificationReducer,
  unReadPrescriptions: unReadPrescriptionsReducer,
  reports: reportsReducer
});
