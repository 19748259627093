import React from 'react';
import { useDispatch } from 'react-redux';
import useFormValidation from '../../../hooks/useFormValidation';
import { validateNoteData } from '../../../util/validation';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { ThemeButton, DialogTitle, } from '../../App.components';
import { regularAlert } from '../../../util/systemAlert';
import { addNote } from '../../../services/actions/patinet';



interface AddNoteModalProps {
  isOpen: boolean;
  handleClose: () => void;
  addTo: {
    type: 'patient' | 'prescription';
    id: string;
  };
  pharmacy: string;
}

interface AddNoteModalStates {
  body: string;
}

export const AddNoteModal: React.FC<AddNoteModalProps> = ({
  isOpen,
  handleClose,
  addTo,
  pharmacy
}: AddNoteModalProps): JSX.Element =>  {

  const dispatch = useDispatch();

  const initialState: AddNoteModalStates = {
    body: ''
  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();
        return dispatch(
          addNote({
            body: values.body,
            patient: addTo.type === 'patient' ? addTo.id : null,
            prescription: addTo.type === 'prescription' ? addTo.id : null,
            pharmacy          
          }, addTo.type),
        );
      },
      successConfirmation: {
        title: 'Created!',
        text: 'Note has been added Successfully.',
      },
      redirect: () => {
        cleanValues();
        handleClose();
      },
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setSubmitting,
    setValues,
    isSubmitting,
    errors,
  } = useFormValidation(initialState, validateNoteData, onSubmit);

  const cleanValues = (): void => {
    setValues(initialState);
  };

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };


  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Note
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
            <form onSubmit={handleSubmit} method="POST">
              <TextField
                error={errors.body && true}
                id="body"
                label={errors.body ? errors.body : 'Note'}
                className=""
                name="body"
                autoComplete="body"
                margin="normal"
                fullWidth
                value={values.body}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows="5"
                />

              <ThemeButton
                isDisabled={isSubmitting}
                text="Save Note"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
