import { useSelector, useDispatch} from 'react-redux';
import { reduxState } from '../services/types/reduxStates';
import { setErrorAlert, removeErrorAlert } from '../services/actions/alert';


export const useGrantedAccessValidator = (): boolean => {
  const { user, loading } = useSelector((state: reduxState) => state.user);
  const dispatch = useDispatch()

  if (user && !loading) {
  
    if (!user.isActive) {
      dispatch(setErrorAlert('Your account has been disabled'));
      return false
    }

    if (!user.confirmation) {
      dispatch(setErrorAlert('Please verify your email before logging in'));
      return false
    }

    if(typeof user.pharmacy !== 'undefined') {
      if ((Array.isArray(user.pharmacy) && !user.pharmacy.length) || !Object.keys(user.pharmacy).length) {
        dispatch(setErrorAlert('You\'re Not allow to  logging in into this pharmacy'));
        return false
      }
    }

    dispatch(removeErrorAlert());
  }

  return true;
}
