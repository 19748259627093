/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableHead, TableCell } from '@material-ui/core';
import { myStyles } from './tableStyles';
import { BlueCheckbox } from '../checkbox/Checkbox';
import { useRole } from '../../../hooks/useRole';


const useStyles = makeStyles(() => createStyles(myStyles));

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement | any>,
    checked: boolean,
  ) => void;
  rowCount: number;
  tabaleHeader: string[];
  isActiveDelete?: boolean;
}

// remember move this to another file
export const EnhancedTableHead: React.FC<EnhancedTableProps> = (
  props: EnhancedTableProps,
): JSX.Element => {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    tabaleHeader,
    isActiveDelete = true
  } = props;
  const { isAdministrator, isOwner } = useRole();


  const IsActiveElement = (isAdministrator || isOwner) && isActiveDelete
  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tr}>
        {IsActiveElement && 
        <TableCell className={classes.matCheck}>
         
            <BlueCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color="primary"
              />
              
        
        </TableCell>
        }
        
          {tabaleHeader.map((name: string, index: number) => 
            // eslint-disable-next-line react/no-array-index-key
            <TableCell className={classes.thCel} align="right" key={index}>
              {name}
            </TableCell>
          )
        }
      </TableRow>
    </TableHead>
  );
};
