import React from 'react';
import moment from 'moment';
import { NoteRelationObject } from '../../../services/types/Note';

interface Props {
  note: NoteRelationObject
}

export const NoteItem: React.FC<Props> = ({note}: Props): JSX.Element =>  
    <article className="note">
      <p className="note_content">
       {note.body}
      </p>
      <footer className="note_footer">
        <p className="note_author to_the_left">
        {note.pharmacist.firstName} {note.pharmacist.lastName} | {moment(note.createdAt).format('MMM/DD/YYYY LT')}
        </p>
      </footer>
    </article>
  
