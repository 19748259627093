import * as React from 'react';
import { TextField } from '@material-ui/core';

interface CityProps {
  value: string;
  label: string;
  className?: string;
  variant?: any;
  error: boolean;
  handleChange: () => void;
  onBlur?:  () => void;
}

export const EmailInput: React.FC<CityProps> = ({
  value,
  handleChange,
  label,
  error,
  className,
  onBlur,
  variant
}: CityProps): JSX.Element => {

  return (
    <>
      <TextField
        error={error}
        id="email"
        label={label}
        className={className && className}
        type="email"
        name="email"
        autoComplete="email"
        margin="normal"
        variant={!variant ? 'standard' : variant}
        fullWidth
        placeholder="Email"
        value={value.toLocaleLowerCase()}
        onChange={handleChange}
        onBlur={onBlur && onBlur}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
}