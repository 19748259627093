// action strings
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// LOGED USER DATA
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR';

export interface UserLogin {
  email: string;
  password: string;
}

export interface User {
  id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: string;
  isActive: boolean;
  confirmation: boolean;
  tokenConfirmation: any;
  createdAt: Date;
  token: string;
  employNumber?: string;
  pharmacy: PharmacyRelations[];
  prescriptions?: PrescriptionRelations[];
}

export interface PharmacyRelations {
  id: string;
  directoryId: string;
  name: string;
  phoneNumber: string;
  email: string;
}

export interface PrescriptionRelations {
  id: string;
  status: string;
  type: string;
  starting: string;
  ending: string;
  createdAt: Date;
  udatedAt: Date;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface userStates {
  token: string | any;
  isAuthenticated: boolean | null;
  loading: boolean;
  user: User | null | undefined | any;
}

export interface LogedUserStates {
  loading: boolean;
  user: User | null | undefined | any;
}

export interface LoadedUserAction {
  type: typeof USER_LOADED;
  payload: User;
}

export interface ErrorLoginUserAction {
  type: typeof AUTH_ERROR;
  payload: User;
}

export interface UserLoginAction {
  type: typeof LOGIN_SUCCESS;
  payload: User;
}

export interface UserLoginFailAction {
  type: typeof LOGIN_FAIL;
  payload: User;
}

export interface UserLogout {
  type: typeof LOGOUT;
  payload: User;
}

export interface UserProfile {
  type: typeof GET_USER_PROFILE_SUCCESS;
  payload: User;
}

export interface UserProfileError {
  type: typeof GET_USER_PROFILE_ERROR;
  payload: User;
}
