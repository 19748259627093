import { ErrorAlertActionType } from '../types/actions';
import {
  ErrorAlertStates,
  SET_ERROR_ALERT,
  REMOVE_ERROR_ALERT,
} from '../types/Alert';

const initialState: ErrorAlertStates = {};

export const alertReducer = (
  state = initialState,
  action: ErrorAlertActionType,
): ErrorAlertStates => {
  const { type, payload } = action;

  switch (type) {
    case SET_ERROR_ALERT:
      return payload;
    case REMOVE_ERROR_ALERT:
      return {};
    default:
      return state;
  }
};
