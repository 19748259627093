/* eslint-disable react/no-array-index-key */
import React from 'react'

import { RouteComponentProps } from 'react-router';
import './prescription-details.scss';
import { Grid, Breadcrumbs, Typography, Fade} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { getEmployees } from '../../services/actions/employees';

import { Loading, Alert, NotesComponent } from '../../layout/App.components';
import { getPrescriptionsById} from '../../services/actions/prescription';

import { getPharmacy } from '../../services/actions/pharmacy';
import { GET_PRESCRIPTION_SUCCESS } from '../../services/types/Prescription';
import { PrescriprionDetails } from './PrescriprionDetails';

interface AlertState {
  message: string;
  status: 'success' | 'warning' | 'danger' | undefined;
}

export const PrescriptionDetailsPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}> | any,
): JSX.Element => {
  const [alert, setAlert] = React.useState<null | AlertState>(null);
  const [open, setOpen] = React.useState(false);
  const { id } = props.match.params;
  const { user } = useSelector((state: reduxState) => state.user);
  const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const { pharmacists } = useSelector((state: reduxState) => state.employee);

  const { prescription, loading } = useSelector((state: reduxState) => state.prescription);

  const dispatch = useDispatch();
 
 


  const dispatchData = React.useCallback(() => {
    if (user && pharmacy) {
      // old trick but works
      if (pharmacists <= 0) {
        dispatch(getEmployees(pharmacy.id))
      }
      if (id) {
        dispatch(getPrescriptionsById({ pharmacyID: pharmacy.id, prescriptionID: id }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pharmacy])

  React.useEffect(() => {
    if (!pharmacy) {
      dispatch(getPharmacy());
    }
    dispatchData()

  }, [dispatch, dispatchData, pharmacy])

  // componentwillunmount
  React.useEffect(() => () => {
    dispatch({
      type: GET_PRESCRIPTION_SUCCESS,
      payload: null,
    });
  }, [dispatch]);


  return (
    <section className="prescription-details-page">
      {loading || !prescription ?
        <Loading />
        :
        <Fade in={!loading}>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to={`/dashboard/${prescription.type === 'Refill' ? 'refills-list' : 'prescriptions-list'}`}>{prescription.type === 'Refill' ? 'Refills' : 'Prescriptions'} List</Link>
              <Typography color="textPrimary">{prescription.type === 'Refill' ? 'Refills' : 'Prescriptions'} details</Typography>
            </Breadcrumbs>
          </Grid>
          {alert &&
            <Alert
              message={alert.message}
              status={alert.status}
              action={() => setOpen(false)}
              open={open}
              />
          }
          <Grid item xs={12} sm={12} md={8}>
            <PrescriprionDetails
            prescription={prescription}
            pharmacy={pharmacy.id}
            user={user}
            setAlert={setAlert}
            setOpen={setOpen}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <NotesComponent
                  notes={prescription.notes}
                  pharmacy={prescription.pharmacy}
                  addTo={{ type: 'prescription', id: prescription.id }}
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Fade>
      }
    </section>
  )
}
