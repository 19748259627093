import { AnyAction, Dispatch } from 'redux';
import {
  GET_PRESCRIPTIONS_FAIL,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTION_FAIL,
  GET_PRESCRIPTION_SUCCESS,
  GET_UNREAD_PRESCRIPTIONS_FAIL,
  GET_UNREAD_PRESCRIPTIONS_SUCCESS,
} from '../types/Prescription';

import { API_HOST } from '../../../config';
import axios from 'axios';
import { getNotifications } from './notifications';
import { loadUser } from './auth';

export const getPrescriptionsById = (data: {
  pharmacyID: string;
  prescriptionID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);

  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/prescriptions/get_by_id`,
      body,
      config,
    );

    dispatch({
      type: GET_PRESCRIPTION_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: GET_PRESCRIPTION_FAIL,
    });

    throw error;
  }
};

export const getPrescriptions = (
  pharmacyId: string,
  limitBy?: number | null,
): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    pharmacyId,
  });

  const URI = limitBy
    ? `${API_HOST}/pharmacy_admin/prescriptions?limitBy=${limitBy}`
    : `${API_HOST}/pharmacy_admin/prescriptions`;

  try {
    const response = await axios.post(URI, body, config);

    dispatch({
      type: GET_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: GET_PRESCRIPTIONS_FAIL,
    });

    throw error;
  }
};

export const deleteMultiplePrescriptions = (data: {
  ids: string[];
  pharmacyID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  try {
    const response = await axios.delete(
      `${API_HOST}/pharmacy_admin/prescriptions/delete/multiple`,
      config,
    );

    dispatch({
      type: GET_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    // console.log(error.response);
    dispatch({
      type: GET_PRESCRIPTIONS_FAIL,
    });
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const deletePrescription = (data: {
  prescription: string;
  pharmacyID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  try {
    const response = await axios.delete(
      `${API_HOST}/pharmacy_admin/prescriptions/delete`,
      config,
    );

    dispatch({
      type: GET_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: GET_PRESCRIPTIONS_FAIL,
    });
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const updateStatus = (data: {
  pharmacy: string;
  prescription: string;
  status: any;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/prescriptions/status`,
      body,
      config,
    );

    dispatch({
      type: GET_PRESCRIPTION_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
  } catch (error) {
    // console.log(error.response);
    dispatch({
      type: GET_PRESCRIPTION_FAIL,
    });
    throw error;
  }
};

export const completeMultiplePrescriptions = (data: {
  ids: string[];
  pharmacyID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data,
  // };

  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/prescriptions/complete/multiple`,
      data,
    );

    dispatch({
      type: GET_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    // console.log(error.response);
    dispatch({
      type: GET_PRESCRIPTIONS_FAIL,
    });
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const assigned = (data: {
  pharmacy: string;
  prescription: string;
  pharmacist: any;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/prescriptions/assigned`,
      body,
      config,
    );

    dispatch({
      type: GET_PRESCRIPTION_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
  } catch (error) {
    // console.log(error.response);
    dispatch({
      type: GET_PRESCRIPTION_FAIL,
    });
    throw error;
  }
};

export const getPrescriptionImage = (data: {
  pharmacy: string;
  prescription: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<string> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/prescriptions/image`,
      body,
      config,
    );

    dispatch(loadUser());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUnReadPrescription = (data: {
  pharmacyID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/prescriptions/unread`,
      body,
      config,
    );

    dispatch(loadUser());
    dispatch({
      type: GET_UNREAD_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNREAD_PRESCRIPTIONS_FAIL,
    });
  }
};

export const updateUnReadPrescription = (data: {
  prescriptionID: string;
  pharmacyID: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/prescriptions/unread`,
      body,
      config,
    );

    dispatch(loadUser());
    dispatch(getPrescriptions(data.pharmacyID));
    dispatch(getNotifications({ pharmacyId: data.pharmacyID }));
    dispatch({
      type: GET_UNREAD_PRESCRIPTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNREAD_PRESCRIPTIONS_FAIL,
    });
  }
};
