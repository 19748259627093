import React from 'react';
import { getPatienImage } from '../../services/actions/patinet';
import { useDispatch } from 'react-redux';
import { SyncLoader } from 'react-spinners';
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogTitle } from '../../layout/App.components';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import print from 'print-js';

export interface ImageModalData {
  pharmacy: string;
  patient?: string;
  imageType: string;
  dependent?: string;
}

interface Props {
  data: ImageModalData;
  isOpen: boolean;
  handleClose: () => void;
}

export const ImageModal: React.FC<Props> = ({ data, isOpen, handleClose }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [image, setImage] = React.useState<{ src: string | null; loading: boolean; }>({
    src: null,
    loading: true
  });
  const loadImage = React.useCallback(
    () => {
      setImage({
        src: null,
        loading: true
      })
      if (isOpen) {
        dispatch(getPatienImage({
          patient: data.patient,
          pharmacy: data.pharmacy,
          imageType: data.imageType,
          dependent: data.dependent
        })).then((result: string) => {
          setImage({
            src: result,
            loading: false
          })
        }).catch(() => setImage({
          src: '',
          loading: false
        }));
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  React.useEffect(() => {
    loadImage();
  }, [loadImage]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Image 
        </DialogTitle>
        <DialogContent dividers>
          <div>
            {!image.loading && image.src ?
              <div style={{
                display: 'flex'

              }}>
                <img
                  style={{ 
                    border: '1px solid #c5c5c5', 
                    width: '70%'
                  }}
                  src={image.src || ''}
                  alt=""
                  loading="lazy"
                  
                  />
                  <div style={{
                    paddingLeft: 10
                  }}>
                  <div className="patient-image-actions">
                    <a href={(image && image.src) || ''} download> <GetAppRoundedIcon style={{ marginRight: 5 }} fontSize="large" />Download </a>
                  </div>
                  <div className="patient-image-actions">
                    <span onClick={() => print({ printable: image.src, type: 'image', base64: true, imageStyle: 'width:70%;' })}> <LocalPrintshopOutlinedIcon style={{ marginRight: 5 }} fontSize="large" /> Print</span>
                  </div>
                  </div>
                
              </div>
              : <div>
                <SyncLoader size={10} color="#5FC9F8" />
                <h4> Loading...</h4>
              </div>}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
