import { useEffect, useState, useCallback } from 'react';

export const useDemensions = (demensions: string): boolean => {
  const [state, setState] = useState(window.matchMedia(demensions).matches);

  const updateDimensions = useCallback(() => {
    setState(window.matchMedia(demensions).matches);
  }, [demensions]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
  });

  return state;
};
