import { useSystemRoles } from './useSystemRoles';

export const useRole = () => {
  // all 
  const isOwnerPharmacy = useSystemRoles('Owner'); // only owner can see and change pharmacy info
  const isAdministrator = useSystemRoles('Administrator');
  const isOntimeAdmin = useSystemRoles('OntimeAdmin');
  const isOwner = isOwnerPharmacy || isOntimeAdmin;
  // read and update status
  const isChiefPharmacis = useSystemRoles('ChiefPharmacist');
  const isPharmacist = useSystemRoles('Pharmacist');
  const isPharmacyTechnician = useSystemRoles('PharmacyTechnician');

  return {
    isOwner,
    isAdministrator,
    isChiefPharmacis,
    isPharmacist,
    isPharmacyTechnician,
    isOntimeAdmin
  };
};
