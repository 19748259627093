import './dashboard.scss';

import * as React from 'react';

import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  createStyles,
} from '@material-ui/core';
import { CircularChart, CountingCard, Loading } from '../../layout/App.components';
import { capital_letter, sortByDate } from '../../util/helpers';
import { getPrescriptionImage, getPrescriptions } from '../../services/actions/prescription';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AngleRightSolid } from '../../../assets/svg/angle-right-solid.svg';
import { Link } from 'react-router-dom';
import { Pharmacy } from '../../services/types/Pharmacy';
import { PrescriptionRelations } from '../../services/types/User';
import { ReactComponent as PrintIcon } from '../../../assets/svg/print-regular.svg';
// import { getPrescriptions } from '../../services/actions/prescription';
import { SyncLoader } from 'react-spinners';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { getPharmacy } from '../../services/actions/pharmacy';
import localStorage from 'redux-persist/es/storage';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { pharmacyReports } from '../../services/actions/reports';
import print from 'print-js';
import { reduxState } from '../../services/types/reduxStates';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
    
    matIcon: {
      fontSize: '20px',
      height: '20px',
      maxWidth: '30px',
      marginRight: '5px',
      color: '#109cf1',

      
    },
  }),
);


export const DashboardPage: React.FC = (): JSX.Element => {
  const { loading } = useSelector((state: reduxState) => state.user);
  const defaultNumber = 0;
  const { pharmacy }: {
    pharmacy: Pharmacy;
  } = useSelector((state: reduxState) => state.pharmacy);

  const {loading: reportLoading, reports} = useSelector((state: reduxState) => state.reports)
  const {loading: prescriptionsLoading, prescriptions} = useSelector((state: reduxState) => state.prescriptions)

  const [printLoading, setPrintLoading] = React.useState(false) 
  // const { prescriptions, loading: prescriptionLoading } = useSelector((state: reduxState) => state.prescriptions);

  const classes = useStyles();
  const dispatch = useDispatch();

  const loadPharmacyData = React.useCallback(async () => {
    const pharmacyToken = await localStorage.getItem('pharmacy')
    if (pharmacyToken && !loading && !pharmacy) {
      dispatch(getPharmacy());
    }
  }, [dispatch, loading, pharmacy]);

 

  const loadReports = React.useCallback(async () => {
    if (!loading && pharmacy) {
      dispatch(pharmacyReports(pharmacy.id));
    }
  }, [dispatch, loading, pharmacy]);



  const loadPresciptions = React.useCallback(async () => {
    if (!loading && pharmacy) {
      dispatch(getPrescriptions(pharmacy.id, 5));
    }
  }, [dispatch, loading, pharmacy]);



  React.useEffect(() => {
      loadPharmacyData();
  }, [loadPharmacyData]);

  React.useEffect(() => {
    loadReports();
}, [loadReports]);

React.useEffect(() => {
  loadPresciptions();
}, [loadPresciptions]);

  const data = {
    labels: ['Females', 'Males'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#2ED47A', '#FFB946 '],
        hoverBackgroundColor: ['#2ED47A', '#FFB946 '],
      },
    ],
  };

  if (!loading && pharmacy) {
    data.datasets[0].data = [
      (!reportLoading && reports) ? reports.patientCount.feminines : defaultNumber,
      (!reportLoading && reports) ? reports.patientCount.masculines : defaultNumber
    ]
  }

  const onLoadingStartPrint = (): void => {
    setPrintLoading(true)
  }

  const onLoadingEndPrint = (): void => {
    setPrintLoading(false)
  }

  const getPrescriptionImageBase64 = ({ prescription }: { prescription: string; }): void => {
      dispatch(getPrescriptionImage({
        prescription,
        pharmacy: pharmacy.id,
      })).then((result: string) => {
        print({ 
          printable: result, 
          type: 'image', 
          base64: true, 
          imageStyle: 'width:70%;', 
          onLoadingStart: onLoadingStartPrint, 
          onLoadingEnd: onLoadingEndPrint,
          onPrintDialogClose: onLoadingEndPrint
        });
      }).catch((e: any) => {
        throw e;
      });
  }


  // const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
  return (
    <>
      {loading || !pharmacy ?
        <Loading />
        :
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={{
                display: 'flex',
                alignContent: 'center'
              }}>
                <AngleRightSolid className={classes.matIcon} />
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="textSecondary">Dashboard</Typography>
                  <Typography color="textSecondary">Home</Typography>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="h4" component="h1">{capital_letter(pharmacy ? pharmacy.name : '')} Dashboard</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="count-item">
                <CountingCard title="Patients That Favorite This Pharmacy" count={(!reportLoading && reports) ? reports.patientCount.count : defaultNumber} />
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="count-item">
                <CountingCard title="Total Prescriptions Processed" count={ (!reportLoading && reports) ? reports.prescriptionCount.count : defaultNumber} />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="count-item">
                <CountingCard title="Upload Prescriptions Today" count={(!reportLoading && reports) ? reports.prescriptionCount.uploadToday : defaultNumber} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="chart-item">
                <Card className={classes.container}>
                  <CardHeader avatar="Recent New Prescriptions" />
                  <Divider />
                  <CardContent>
                    <div className="precription_dash_list_container">
                      <div
                        className="precription_dash_list header">
                        <div className="to_left">
                          <span className="to_right">
                            Type
                        </span>
                        </div>
                        <div>
                          <span >
                            Date
                        </span>
                        </div>
                        <div>
                          <span className="to_left">
                            action
                        </span>
                        </div>
                      </div>
                      {(!prescriptions || printLoading) && 
                        <div style={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center'
                          
                        }}>
                        <div>
                          <SyncLoader size={10} color="#5FC9F8" />
                          {printLoading ? <h4> Loading Print...</h4> : <h4> Loading...</h4>}
                          
                        </div>
                        </div>
                      }
                      {!printLoading && prescriptions && prescriptions.length ?
                        sortByDate(prescriptions).map(
                          (prescription: PrescriptionRelations) =>
                            <div
                              className="precription_dash_list hover"
                              key={prescription.id}>
                              <div>
                                <span className="to_right">
                                  {prescription.type}
                                </span>
                              </div>
                              <div>
                                <span>
                                  {moment(prescription.createdAt).format('MMM. DD, YYYY')}{' '} {moment(prescription.createdAt).format('LT')}
                                </span>
                              </div>
                              <div
                                className="icon-wrap">
                                <Link
                                 title="View details"
                                  to={`/dashboard/prescription-details/${prescription.id}`} style={{
                                    marginLeft: 'auto'
                                  }}>
                                  <div className="view-icon">
                                    <VisibilityOutlinedIcon />
                                  </div>
                                </Link>
                                <span
                                  title="Print"
                                  style={{
                                    marginLeft: 'auto',
                                  }}
                                  onClick={() => getPrescriptionImageBase64({ prescription: prescription.id })}>
                                  <div className="print-icon">
                                    <PrintIcon style={{
                                      width: 50
                                    }}
                                               />
                                  </div>
                                </span>
                              </div>
                            </div>
                              ,
                        )
                        :
                        <>
                          {(!printLoading || prescriptionsLoading) &&
                            <h5 style={{ textAlign: 'center', marginTop: 35 }}>
                              No new prescriptions
                            </h5>
}
                          
                        </>
                      }
                    </div>
                  </CardContent>
                </Card>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="chart-item">
                <CircularChart title="Patient Demographics" data={data} />
              </div>
            </Grid>
            </Grid>
        </>
      }
    </>
  );
};
