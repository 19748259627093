import * as React from 'react';
import { routes } from './routing/routes';
import { ProtectedRoute } from './routing/ProtectedRoute';

export const Routes: React.FC = (): JSX.Element => (
  <>
    {routes.map((route, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ProtectedRoute key={i} path={route.path} component={route.component} />
    ))}
  </>
);
