import { ReportCount } from "./Pharmacy";

export interface ReportsStates {
    loading: boolean;
    reports?: ReportCount | null;
  }
  
  export const SET_REPORT = 'SET_REPORT';
  export const ERROR_SET_REPORT = 'ERROR_SET_REPORT';
  
  export interface SetReports {
    type: typeof SET_REPORT;
    payload: ReportCount;
  }
  
  export interface ErrorSetReports {
    type: typeof ERROR_SET_REPORT;
    payload: null;
  }
  