import * as React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { cities } from '../../../util/cityAndState';

interface Props {
  value: string,
  label: string,
  error: boolean,
  handleChange: () => void;
}

export const TownInput: React.FC<Props> = ({
  value,
  handleChange,
  label,
  error
}: Props): JSX.Element => {

  return (
    <>
      <InputLabel error={error} htmlFor="town">{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        fullWidth
        inputProps={{
          name: 'town',
          id: 'town',
        }}>
        {cities.map((city: string, i: any) => (
          <MenuItem key={i} value={city}>{city}</MenuItem>
        ))}
      </Select>
    </>
  );
}