/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import './pharmacy-info.scss'
import {
  Grid,
  Card,
  CardContent
} from '@material-ui/core';
import { ThemeButton, Loading } from '../../layout/App.components';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { getPharmacy } from '../../services/actions/pharmacy';
import { PharmacyInformation } from './Pharmacyformation';
import { PharmacyInformationEdit } from './PharmacyInformationEdit';
import { Pharmacy } from '../../services/types/Pharmacy';
import { PharmacyBusinessHours } from './PharmacyBusinessHours';
import { PharmacyLogoImage } from './PharmacyLogoImage';
import { PharmacyShopper } from './PharmacyShopper';




export const PharmacyInfoPage: React.FC = (): JSX.Element => {
  const { pharmacy, loading }: {
    pharmacy: Pharmacy;
    loading: boolean;
  } = useSelector((state: reduxState) => state.pharmacy);

  const [isEditMode, setEditMode] = React.useState(false);
  const [isSubmitEditFrom, setSubmitEditFrom] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPharmacy())
  }, [dispatch])

  const editeMode = (): void => {
    setEditMode(true);
  };

  const cancelEditeMode = (): void => {
    setEditMode(false);
  };

  const submitEditFrom = (): void => {
    setSubmitEditFrom(true);
  };



  return (
    <section className="pharmacy-details-page">
      {loading && !pharmacy ?
        <Loading />
        :
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid item xs={12}>
              <Card className="contact-info">
                <CardContent>
                  <div className="header">
                    <PharmacyLogoImage pharmacy={pharmacy} />

                    <span className="name">{pharmacy.name}</span>
                    {pharmacy.hasRx30 &&
                      <span className="role">
                        Rx30 Key: {pharmacy.rx30Number}{' '}
                      </span>
                    }

                    <span
                      className={`ont-badge ${
                        pharmacy.isActive ? 'active' : 'archived'
                        }`}>
                      {pharmacy.isActive ? 'Active' : 'Archived'}
                    </span>
                  </div>

                  {!isEditMode &&
                    <div className="detail-btn" onClick={editeMode}>
                      <ThemeButton
                        text="Edit Pharmacy"
                        clases="tc-btn"
                        color="primary"
                        variant="contained"
                        />
                    </div>
                  }
                  {isEditMode &&
                    <>
                      <div className="detail-btn" onClick={submitEditFrom}>
                        <ThemeButton
                          text="Save Changes"
                          clases="tc-btn"
                          color="primary"
                          variant="contained"
                          />
                      </div>
                      <div className="cancel-btn" onClick={cancelEditeMode}>
                        <ThemeButton
                          text="Cancel"
                          clases="tc-btn"
                          color="primary"
                          />
                      </div>
                    </>
                  }
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <PharmacyShopper pharmacy={pharmacy} />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <PharmacyBusinessHours businessHours={pharmacy.businessHours} pharmacy={pharmacy.id} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card className="contact-info">
                  <CardContent>
                    {!isEditMode && <PharmacyInformation pharmacy={pharmacy} />}
                    {isEditMode &&
                      <PharmacyInformationEdit
                        pharmacy={pharmacy}
                        submitEditFrom={isSubmitEditFrom}
                        setSubmitEditFrom={setSubmitEditFrom}
                        setEditMode={setEditMode}
                        />
                    }
                  </CardContent>
                </Card>
              </Grid>
             
            </Grid>
          </Grid>
        </Grid>
      }
    </section>
  );
};
