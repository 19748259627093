import { AnyAction, Dispatch } from 'redux';
import { ERROR_SET_REPORT, SET_REPORT } from './../types/reports';

import { API_HOST } from '../../../config';
import axios from 'axios';

export const pharmacyReports= (pharmacyID: string): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  
    try {
      const response = await axios.get(
        `${API_HOST}/pharmacy_admin/pharmacy/report/${pharmacyID}`);
  
      dispatch({
        type: SET_REPORT,
        payload: response.data,
      });

    } catch (error) {
      dispatch({
        type: ERROR_SET_REPORT,
      });
  
      throw error;
    }
  };