import * as React from 'react';
import './login.scss';
import { Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import {
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Container,
} from '@material-ui/core';
import { ThemeButton, ErrorAlert} from '../../layout/App.components';
import { login } from '../../services/actions/auth';
import useFormValidation from '../../hooks/useFormValidation';
import { validateAuth } from '../../util/validation';
import { useGrantedAccessValidator } from '../../hooks/useGrantedAccessValidator';
import { formatPhoneNumber } from '../../util/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100vh',
    },
    container: {
      flex: '1',
      padding: theme.spacing(5, 3),
      maxWidth: '90%',
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
    textField: {},
  }),
);

export const LoginPage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { isAuthenticated, loading } = useSelector(
    (state: reduxState) => state.auth,
  );

  const hasValidAccess = useGrantedAccessValidator();

  const dispatch = useDispatch();

  const unmounted = React.useRef(false);

  React.useEffect(() => () => { unmounted.current = true }, []);

  const initialState = {
    phoneNumber: '',
    password: '',
  };

  const onSubmit = async () => {
    dispatch(login(formatPhoneNumber(values.phoneNumber), values.password))
      .then(() => {
        if (!unmounted.current) { cancelSubmit() }
      })
      .catch(() => {
        if (!unmounted.current) { cancelSubmit() }
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateAuth, onSubmit);

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  if (isAuthenticated && !loading) {
    if (hasValidAccess) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { preventLastLocation: false },
          }}
          />
      );
    }

  }
  return (
    <Container maxWidth="sm">
      <div className="login-page">
        <div className={classes.root}>
          <Paper className={classes.container}>
            <ErrorAlert />
            <Typography variant="h5" component="h3">
              Ontime App
          </Typography>
            <Typography component="p">Description</Typography>
            <form onSubmit={handleSubmit} method="POST">

              <TextField
                error={errors.phoneNumber && true}
                id="outlined-password-input"
                label={errors.phoneNumber ? errors.phoneNumber : 'Phone Number'}
                className={classes.textField}
                type="number"
                name="phoneNumber"
                autoComplete="current-phoneNumber"
                margin="normal"
                variant="outlined"
                placeholder="7875555555"
                fullWidth
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                /> 
                 
              <TextField
                error={errors.password && true}
                id="outlined-password-input"
                label={errors.password ? errors.password : 'Password'}
                className={classes.textField}
                type="password"
                name="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                />

              <ThemeButton
                text="login"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
            <Link className="forgot-link" to="/forgot-password">
              Forgot your password?
          </Link>
          </Paper>
        </div>
      </div>
    </Container>
  );
};
