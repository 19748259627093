import React from 'react';
import { SyncLoader } from 'react-spinners';
import { getPrescriptionImage } from '../../services/actions/prescription';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useDispatch } from 'react-redux';

type Props = {
  prescriptionId: string;
  pharmacyId: string;
}

export const PrescriptionImage: React.FC<Props> = ({
  prescriptionId,
  pharmacyId
}: Props): JSX.Element => {
  const [image, setImage] = React.useState<string | null>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPrescriptionImage({
      prescription: prescriptionId,
      pharmacy: pharmacyId,
    })).then((result: string) => setImage(result)).catch(() => setImage(null));
  }, [prescriptionId, pharmacyId, dispatch]);
  return (
    <>
      {!image &&
        <div style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <div>
            <SyncLoader size={10} color="#5FC9F8" />
            <h4> Loading...</h4>
          </div>
        </div>

      }
      <img
        style={{ border: '1px solid #c5c5c5' }}
        src={image || ''}
        alt=""
        />
      <div className="download">
        <a href={image || ''} download>Download Prescription <GetAppRoundedIcon style={{ marginLeft: 5 }} fontSize="large" /></a>
      </div>
    </>
  )
};

