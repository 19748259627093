import * as React from 'react';
import { User } from '../../services/types/User';
import useFormValidation from '../../hooks/useFormValidation';
import { validateEditeUserData } from '../../util/validation';
import { TextField } from '@material-ui/core';
import { regularAlert } from '../../util/systemAlert';
import { updateProfole } from '../../services/actions/user';
import { useDispatch } from 'react-redux';
import { PhoneNumberInput, EmailInput } from '../../layout/App.components';

interface Props {
  user: User;
  submitEditFrom: boolean;
  setSubmitEditFrom: (value: boolean) => void;
  setEditMode: (value: boolean) => void;
}

interface EditStates {
  firstName: string | any;
  lastName: string | any;
  email: string | any;
  phoneNumber: string | any;
}

export const UserProfileInformationEdit: React.FC<Props> = ({
  user,
  submitEditFrom,
  setSubmitEditFrom,
  setEditMode,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-underscore-dangle
  const _form = React.createRef<HTMLFormElement>();

  const initialState: EditStates = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
  };

  const onSubmit = () => {
    if (submitEditFrom) {
      regularAlert({
        callback: () => {
          cancelSubmit();
          setSubmitEditFrom(false);
          return dispatch(
            updateProfole(
              {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
              },
              user.id,
            ),
          );
        },
        successConfirmation: {
          title: 'Updated!',
          text: 'Profile has been Updated Successfully.',
        },
        redirect: () => {
          setSubmitEditFrom(false);
          setEditMode(false);
        },
        callbackIfError: () => cancelSubmit(),
      });
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateEditeUserData, onSubmit);

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  const submitEditFromCb = React.useCallback(() => {
    if (Object.entries(errors).length !== 0) {
      setSubmitEditFrom(false);
    }
  }, [setSubmitEditFrom, errors]);

  React.useEffect(() => {
    if (submitEditFrom) {
      const cForm: any = _form.current;
      cForm.dispatchEvent(new Event('submit'));
    }
    submitEditFromCb();
  }, [submitEditFrom, _form, submitEditFromCb]);

  return (
    <>
      <div className="user-info">
        <div className="contact-header">
          <span>Contact Information</span>
        </div>
        <form method="POST" id="editForm" onSubmit={handleSubmit} ref={_form}>
          <div className="info-container">
            <TextField
              error={errors.firstName && true}
              id="firstName"
              label={errors.firstName ? errors.firstName : 'First Name'}
              className=""
              name="firstName"
              autoComplete="firstName"
              margin="normal"
              fullWidth
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              />
            <TextField
              error={errors.lastName && true}
              id="lastName"
              label={errors.lastName ? errors.lastName : 'Last Name'}
              className=""
              name="lastName"
              autoComplete="lastName"
              margin="normal"
              fullWidth
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              />
              
            <EmailInput
              error={errors.email && true}
              label={errors.email ? errors.email : 'Email'}
              value={values.email}
              handleChange={handleChange}
              />  

            <PhoneNumberInput
              label={errors.phoneNumber ? errors.phoneNumber : 'Phone Number'}
              error={errors.phoneNumber && true}
              id="phoneNumber"
              className=""
              name="phoneNumber"
              autoComplete="phoneNumber "
              fullWidth
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              />
          </div>
        </form>
      </div>
    </>
  );
};
