import './menu.scss';

import * as React from 'react';

import { Badge, Divider } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { ReactComponent as PrescriptionIcon } from '../../../../assets/svg/file-prescription-light.svg';
import { ReactComponent as RefillIcon } from '../../../../assets/svg/prescription-bottle-light.svg';
import { reduxState } from '../../../services/types/reduxStates';
import { useRole } from '../../../hooks/useRole';
// import { useUnReadPrescriptions } from '../../../hooks/useUnReadPrescriptions';
import { useSelector } from 'react-redux';

interface MenuProps {
  toggleAction: string;
}

export const Menu: React.FC<MenuProps> = ({
  toggleAction,
}: MenuProps): JSX.Element => {
  const { isAdministrator, isOwner, isOntimeAdmin } = useRole();

  const { unReadPrescriptions } = useSelector((state: reduxState) => state.unReadPrescriptions);

  return (
    <span className={`menu-host ${toggleAction} `}>
      <ul className="menu-ul">
        <li className="menu-li">
          <Link
          className="item-link"
            to="/dashboard">
            <DashboardIcon className="item-icon" />
            <span className="item-text">Dashboard</span>
          </Link>
        </li>
        <li className="menu-li">
          <NavLink
            activeClassName="active"
            className="item-link"
            to="/dashboard/patients-list">
            <AssignmentIndIcon className="item-icon" />

            <span className="item-text">Patients</span>
          </NavLink>
        </li>
        <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
            to="/dashboard/prescriptions-list">
            <PrescriptionIcon className="item-icon" style={{
              marginLeft: 2,
              width: 10
            }}
                                                    />

              <span className="item-text">Prescriptions</span>
            <Badge overlap="rectangular" style={{
              marginLeft: 'auto'
            }} badgeContent={
              unReadPrescriptions &&
                unReadPrescriptions.length > 0 ?
                unReadPrescriptions.filter(unRead => unRead.prescriptionType === 'Prescription').length : 0
            } color="secondary"
              />
            </NavLink>
        </li>
        <li className="menu-li">
          <NavLink
            activeClassName="active"
            className="item-link"
            to="/dashboard/refills-list">
            <RefillIcon className="item-icon" 
              style={{
                marginLeft: 2,
                width: 10
              }}
              />

            <span className="item-text">Refills </span>
            <Badge overlap="rectangular" style={{
              marginLeft: 'auto'
            }} badgeContent={
              unReadPrescriptions &&
                unReadPrescriptions.length > 0 ?
                unReadPrescriptions.filter(unRead => unRead.prescriptionType === 'Refill').length : 0} color="secondary"
                                                                                                       />
          </NavLink>
        </li>
        {(isAdministrator || isOwner || isOntimeAdmin) && 
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/employee-list">
              <PermIdentityOutlinedIcon className="item-icon" />

              <span className="item-text">Employees</span>
            </NavLink>
          </li>
       }
      
        
        <li className="divider-item">
          <Divider className="divider" />
        </li>
        {(isAdministrator || isOwner || isOntimeAdmin) && 
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/pharmacy-info">
              <MoreHorizIcon className="item-icon" />
              <span className="item-text">Pharmacy Info</span>
            </NavLink>
        </li>
  }
      
        {/* { (isAdministrator ||  isOwner) && 
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/settings">
              <MoreHorizIcon className="item-icon" />
              <span className="item-text">Setting</span>
            </NavLink>
        </li>
        } */}
      </ul>
    </span>
  );
};

