import './App.scss';

import * as React from 'react';

import { SideBar, TopNav } from './layout/App.components';

import { Redirect } from 'react-router';
import { Routes } from './Routes';
import { ThemeProvider } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { reduxState } from './services/types/reduxStates';
import { useGrantedAccessValidator } from './hooks/useGrantedAccessValidator';
import { useSelector } from 'react-redux';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#f44336',
    },
  },
  overrides: {
    // Style sheet name ⚛️
  },
});
export const AppContainer: React.FC = (): JSX.Element => {
  const { isAuthenticated } = useSelector((state: reduxState) => state.auth);

  const hasValidAccess = useGrantedAccessValidator();

  if (!isAuthenticated) {
    if (hasValidAccess) {
      return <Redirect to="/login" />;
    }
  }
  return (
    <div className="App">
      <TopNav />
      <SideBar />
      <div className="main-content">
        <main>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </main>
      </div>
    </div>
  );
};
