/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { getPharmacyDocs, uploadImage } from '../../services/actions/pharmacy';
import { Pharmacy } from '../../services/types/Pharmacy';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

interface Props {
  pharmacy: Pharmacy
}

export const PharmacyLogoImage: React.FC<Props> = ({pharmacy}: Props): JSX.Element => {
  const [image, setImage] = React.useState({ preview: '', raw: '' });

  const [logo, setLogo] = React.useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  const acceptedTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  React.useEffect(() => {
    dispatch(getPharmacyDocs({
      imageKey: pharmacy.logo,
      ContentType: 'image/jpg'
    })).then((result: string) => setLogo(result)).catch(() => setLogo(undefined));
  }, [dispatch, pharmacy.logo]);

  const isValidFileType = (fileType: string): boolean => acceptedTypes.includes(fileType);
  const getFileExtension = (filename: string) => filename.split('.').pop();


  const handleUploadClick = (e: any) => {
    e.preventDefault()

    if (typeof e.target.files[0] !== 'undefined') {


      if (!isValidFileType(e.target.files[0].type)) {
        alert('Only images are allowed, only png or jpg are allowed.');
        return;
      }

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      const fileName = `${pharmacy.id}.${getFileExtension(e.target.files[0].name)}`;
      const file = new File([e.target.files[0]], fileName);


      const formData = new FormData()
      formData.append('file', file, fileName);
      formData.append('id', pharmacy.id);

      regularAlert({
        callback: () => dispatch(uploadImage(formData)),
        successConfirmation: {
          title: 'Updated!',
          text: 'Profile image has been Updated Successfully.',
        }
      });
    }

  };
  return (
    <div className="image-container">
      <div className="img-wrap">
        <input
          accept="image/*"
          className="input"
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadClick}
          />

        <label htmlFor="contained-button-file" className="edit-btn"> <EditOutlinedIcon /></label>
        <img
          src={image.preview ? image.preview : logo}
          alt={pharmacy.name}
          />
      </div>
    </div>
  )
}
