import {
  BusinessHoursBase,
  BusinessHoursRTO,
  GET_PHARMACIES_FAIL,
  GET_PHARMACIES_SUCCESS,
} from '../types/Pharmacy';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { LOGIN_FAIL } from '../types/User';
import { REMOVE_ERROR_ALERT } from '../types/Alert';
import { ReportCount } from './../types/Pharmacy';
import axios from 'axios';
import { loadUser } from './auth';
import { setErrorAlert } from './alert';

// import { getUnReadPrescription } from './prescription';

export const getPharmacy = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    id: localStorage.pharmacy,
  });

  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/get`,
      body,
      config,
    );

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
    dispatch({
      type: REMOVE_ERROR_ALERT,
    });
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch(setErrorAlert(error.response.data.message));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: GET_PHARMACIES_FAIL,
    });
  }
};

export const getPharmacyDetails = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    id,
  });
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/get`,
      body,
      config,
    );

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    // eslint-disable-next-line no-empty
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: GET_PHARMACIES_FAIL,
    });
  }
};

export const updatePharmacy = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/pharmacy/update`,
      body,
      config,
    );

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const uploadImage = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/upload`,
      data,
      config,
    );

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};
export const uploadShopper = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/upload/shopper`,
      data,
      config,
    );
    dispatch(getPharmacy());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const addBusinessHours = (data: {
  businessHours: BusinessHoursBase[];
  pharmacy: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/add_hours`,
      body,
      config,
    );

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });

    dispatch(getPharmacy());
    dispatch(getPharmacy());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const updateBusinessHours = (data: {
  businessHours: BusinessHoursRTO[];
  pharmacy: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/update_hours`,
      body,
      config,
    );

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });

    dispatch(getPharmacy());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const loadNewPharmacy = (id: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  localStorage.setItem('pharmacy', id);
  dispatch(getPharmacy());
};

export const getPharmacyDocs = (data: { imageKey: string, ContentType: string }): any => async (dispatch: Dispatch<AnyAction>): Promise<string> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/pharmacy/brand_docs`,
      body,
      config,
    );

    dispatch(loadUser());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPharmacyReport = (pharmacyID: string ): any => async (dispatch: Dispatch<AnyAction>): Promise<ReportCount> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios.get<ReportCount>(
      `${API_HOST}/pharmacy_admin/pharmacy/report/${pharmacyID}`,
      config,
    );

    dispatch(loadUser());
    return response.data;
  } catch (error) {
    throw error;
  }
};
