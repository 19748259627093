import React from 'react';
import moment from 'moment';
import './prescription-details.scss';
import { Grid, Card, CardContent, Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { Pharmacist } from '../../services/types/Pharmacist';
import { useRole } from '../../hooks/useRole';
import { ThemeButton,} from '../../layout/App.components';
import { updateStatus, assigned, deletePrescription, updateUnReadPrescription } from '../../services/actions/prescription';
import { cryptoService } from '../../util/crypto';
import { confirmationAlert } from '../../util/systemAlert';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import InfoSharpIcon from '@material-ui/icons/InfoSharp';

import { PrescriptionImage } from './PrescriptionImage';
import { User } from '../../services/types/User';
import { Prescription } from '../../services/types/Prescription';

type Props = {
  prescription: Prescription;
  user: User;
  pharmacy: string;
  setAlert: (value: any) => void;
  setOpen: (value: any) => void;
}

export const PrescriprionDetails: React.FC<Props> = ({
  prescription,
  user,
  pharmacy,
  setAlert,
  setOpen
}): JSX.Element => {
  const { isOwner, isAdministrator } = useRole();
  const hasPermissions = isOwner || isAdministrator;
  const { pharmacists } = useSelector((state: reduxState) => state.employee);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!prescription.hasBeenRead) {
      dispatch(updateUnReadPrescription({ prescriptionID: prescription.id, pharmacyID: pharmacy }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription])

  const filtered = (arr: any) => arr.filter((el: any) => el !== null);

  const updatePrescriptionStatus = async (_event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    try {
      if (prescription) {
        const data = {
          pharmacy,
          prescription: prescription.id,
          status: _event.target.value
        }
        await dispatch(updateStatus(data))
        setAlert({
          message: `Prescription Status has been updated to ${data.status}`,
          status: 'success'
        })
        setOpen(true);
      }
    } catch (error) {
      setAlert({
        message: error,
        status: 'danger'
      })
      setOpen(true);
    }
  }

  const assignPharmacist = async (_event: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    if (!_event.target.value) {
      return;
    }

    try {
      if (prescription) {
        const data = {
          pharmacy,
          prescription: prescription.id,
          pharmacist: _event.target.value
        }
        await dispatch(assigned(data));

        setAlert({
          message: 'Pharmacist has been asigned.',
          status: 'success'
        })
        setOpen(true);
      }
    } catch (error) {
      setAlert({
        message: error,
        status: 'danger'
      })
      setOpen(true);
    }
  }
  const assignYourSelf = async (): Promise<void> => {
    try {
      if (prescription) {
        const data = {
          pharmacy,
          prescription: prescription.id,
          pharmacist: user.id
        }
        await dispatch(assigned(data))
        setAlert({
          message: 'Prescription has been asigned to you.',
          status: 'success'
        })
        setOpen(true);
      }
    } catch (error) {
      setAlert({
        message: error,
        status: 'danger'
      })
      setOpen(true);
    }
  }

  const onDelete = (): void => {
    if (!prescription) {
      return;
    }
    confirmationAlert({
      title: 'Are you sure you want to delete this Pharmacist?',
      text: 'This change is permanent!',
      confirmButtonText: 'Yes, delete it!',
      successConfirmation: {
        title: 'Deleted!',
        text: 'Prescription has been deleted.',
      },
      callback: () => dispatch(deletePrescription({
        prescription: prescription.id,
        pharmacyID: pharmacy
      })),
      redirect: () => window.location.replace('/dashboard/prescriptions-list'),
    });
  }
  
  return (
    <Card className="card_shadow">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="card_title">{prescription.type === 'Prescription' ? 'Prescription' : 'Refill Code'}</div>
          </Grid>
          <Grid item xs={8}>
            {!prescription.code &&
              <PrescriptionImage pharmacyId={pharmacy} prescriptionId={prescription.id} />
            }
          </Grid>
          {(prescription.code && prescription.code.numbers.length > 0) &&
            <Grid item xs={8}>
              <div className="barcode">
                <img src="https://www.allbarcodesystems.com/wp-content/uploads/2017/03/free-code-39-barcode.png" alt="" />
              </div>
              {prescription.code.numbers.map((number: string, i: number) =>
                // eslint-disable-next-line react/no-array-index-key
                <div className="barcode" key={i}>
                  <div style={{
                    fontSize: 30,
                    width: '100%',
                    textAlign: 'center'
                  }}>{number}</div>
                </div>

              )}
            </Grid>
          }

          <Grid item xs={12} sm={12} md={4}>
            <div className="action">
              <div className="title">Status</div>
              <div>
                <Select
                  className="select_text"
                  value={prescription.status}
                  onChange={updatePrescriptionStatus}
                  displayEmpty
                  fullWidth
                  name="status">
                  <MenuItem style={{ color: '#ffb946' }} value="pending">Pending</MenuItem>
                  <MenuItem style={{ color: '#109CF1' }} value="progress">In Progress</MenuItem>
                  <MenuItem style={{ color: '#2ED47A' }} value="completed">Completed</MenuItem>
                  <MenuItem style={{ color: '#F76856' }} value="denied">Denied</MenuItem>

                </Select>
              </div>
            </div>
            <div className="action">
              {!hasPermissions &&
                <>
                  {prescription.pharmacist ?
                    <>
                      <div className="title">Assigned to {prescription.pharmacist.id === user.id && 'You'} </div>
                      <div className="text"> {prescription.pharmacist.firstName} {prescription.pharmacist.lastName} </div>
                    </>
                    :
                    <div>
                      <span className="assign_link" onClick={assignYourSelf}> Assign to my self</span>
                    </div>
                  }
                </>

              }
              {hasPermissions &&

                <>
                  <div className="title">Assigned to</div>
                  <div style={{ marginBottom: 15 }}>
                    <span className="assign_link" onClick={assignYourSelf}> Assign to my self</span>
                  </div>
                  <div>
                    <Select
                      className="select_text"
                      value={prescription.pharmacist ? prescription.pharmacist.id : ''}
                      onChange={assignPharmacist}
                      displayEmpty
                      fullWidth
                      name="status">
                      <MenuItem value="">
                        <em>Select a Pharmacist</em>
                      </MenuItem>
                      {pharmacists &&
                        filtered(pharmacists).map((pharmacist: Pharmacist) =>
                          <MenuItem
                            key={pharmacist.id}
                            value={pharmacist.id}>
                            {pharmacist.firstName} {pharmacist.lastName}
                          </MenuItem>
                        )
                      }
                    </Select>

                  </div>
                </>

              }

            </div>

            <div className="action">
              {prescription.isDelivery &&
                <div className="delivery">
                  <LocalShippingIcon /> <span style={{ paddingLeft: 10 }}>This Patient choosed Delivery</span>
                </div>
              }

              {prescription.deliveryMethod && prescription.deliveryMethod.ontime_api_integer !== 0 &&
                <div className="delivery">
                  <InfoSharpIcon />
                  <span style={{ paddingLeft: 10 }}>{prescription.deliveryMethod.method}</span>
                </div>
              }

              <div className="title">View Patient</div>
              {prescription.dependent &&
                <div>
                  <Link
                    to={`/dashboard/patient-details/${prescription.patient.id}/`} target="_blank">
                    <div className="text">{cryptoService.decrypt(prescription.dependent.firstName)} {cryptoService.decrypt(prescription.dependent.lastName)}</div>
                  </Link>
                  <div className="title" style={{ marginTop: 10 }}>Guardian</div>
                </div>
              }
              <Link
                to={`/dashboard/patient-details/${prescription.patient.id}/`} target="_blank">
                <div className="text link">{cryptoService.decrypt(prescription.patient.firstName)} {cryptoService.decrypt(prescription.patient.lastName)}</div>
              </Link>
            </div>
            {prescription.attachedNote && prescription.attachedNote.trim() !== '' &&
              <div className="action">
                <div className="title">Attached Note:</div>
                <div className="attached-note">
                  {prescription.attachedNote.replace('null', '')}
                </div>
              </div>
            }

            {prescription.starting &&
              <div className="action">
                <div className="title">Date/Time Started</div>
                <div className="date">{moment(prescription.starting).format('MMM/DD/YYYY LT')}</div>
              </div>
            }
            {prescription.ending &&
              <div className="action">
                <div className="title">Date/Time Ended</div>
                <div className="date">{moment(prescription.ending).format('MMM/DD/YYYY LT')}</div>
              </div>
            }
            {hasPermissions &&
              <div className="delete_btn" onClick={onDelete}>
                <ThemeButton
                  text="Delete Prescription"
                  clases="tc-btn"
                  color="error"
                  variant="contained"
                  />
              </div>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
