import { PharmacyRelations, PrescriptionRelations } from './User';
import { NoteRelationObject } from './Note';

export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAIL = 'GET_PATIENT_FAIL';

export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL';

export interface PatientRelations {
  id: string;
  avatar: string;
  isActive: boolean;
  email: string;
  gender: string;
  firstName: string;
  lastName: string;
  dayOfBirth: Date;
  address?: string;
  state: string;
  town: string;
  zipCode: string;
  phoneNumber: string;
  healthPlan?: string;
  personalIdentification?: string;
  createdAt: Date;
}

export interface Patient {
  id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  email: string;
  isActive: boolean;
  confirmation: boolean;
  tokenConfirmation: any;
  isFirstTileLogin: boolean;
  createdAt: Date;
  token?: string;
  expiresIn?: string;
  dayOfBirth: Date;
  address: string;
  state: string;
  town: string;
  zipCode: string;
  healthPlan?: string;
  personalIdentification?: string;
  pharmacy: PharmacyRelations;
  prescriptions?: PrescriptionRelations[];
  notes: NoteRelationObject[];
  dependents?: PatientRelations[];
}

export interface PatientStates {
  loading: boolean;
  patient: Patient | null | undefined | any;
}

export interface PatientsStates {
  loading: boolean;
  patients: Patient[] | null | undefined | any;
}

export interface GetPatientAction {
  type: typeof GET_PATIENT_SUCCESS;
  payload: Patient;
}

export interface ErrorGetPatientAction {
  type: typeof GET_PATIENT_FAIL;
  payload: null;
}

export interface GetPatientsAction {
  type: typeof GET_PATIENTS_SUCCESS;
  payload: Patient[];
}

export interface ErrorGetPatientsAction {
  type: typeof GET_PATIENTS_FAIL;
  payload: null;
}
