/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import {
  Dialog,
  DialogContent,
} from '@material-ui/core';

import {
  DialogTitle,
} from '../../layout/App.components';
import { useDispatch } from 'react-redux';
import { getPharmacyDocs } from '../../services/actions/pharmacy';


interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  shopper: string;
}


export const ShopperImageModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  shopper,
}: ModalProps): JSX.Element => {


  const [shooperPDF, setShooperPDF] = React.useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPharmacyDocs({
      imageKey: shopper,
      ContentType: 'application/pdf'
    })).then((result: string) => setShooperPDF(result)).catch(() => setShooperPDF(undefined));
  }, [dispatch, shopper]);
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Pharmacy Shopper
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
           {shooperPDF && 
             <>
              <embed
                src={shooperPDF}
                type="application/pdf"
            
                width="100%" height="500"
                             />
             </>
           }
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
};
