import { ERROR_SET_REPORT, SET_REPORT } from './../types/reports';

import { ReportsActionType } from '../types/actions';
import { ReportsStates } from "../types/reports";

const initialState: ReportsStates = {   
    loading: true,
    reports: null
  };

  export const reportsReducer = (
    state = initialState,
    action: ReportsActionType,
  ): ReportsStates => {
    const { type, payload } = action;
  
    switch (type) {
      case SET_REPORT:
        return {
          ...state,
          loading: false,
          reports: payload,
        };
      case ERROR_SET_REPORT:
        return {
          ...state,
          loading: false,
          reports: null,
        };
      default:
        return state;
    }
  };