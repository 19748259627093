import React from 'react';
import { Select, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ThemeButton } from '../../layout/App.components';
import { BusinessHoursBase, BusinessHours, BusinessHoursRTO } from '../../services/types/Pharmacy';
import { getDateString, getHoursList } from '../../util/helpers';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginLeft: 'auto'
    },
    input: {
      marginLeft: theme.spacing(1),
      minWidth: 120,
    }
  }),
);

interface Props {
  businessHoursState: BusinessHoursBase[] | BusinessHours[] | BusinessHoursRTO[];
  handleChange: (e: React.ChangeEvent<{ value: unknown }>, index: number, hourtime: 'openTime' | 'closeTime') => void;
  onSubmit: (_event: any) => void;
  buttonText: string;
  loading: boolean;
}

export const BusinessHoursForm: React.FC<Props> = ({
  businessHoursState,
  handleChange,
  onSubmit,
  buttonText,
  loading
}: Props):JSX.Element => {
  const classes = useStyles();
  const defaultKey = 10000000000000;


  return (
    <>
      <div className="edit-form-container hours-contaier">
        <form onSubmit={onSubmit} method="POST">
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
            <span style={{ marginRight: 15 }}>Day</span>
            <div style={{ marginLeft: 'auto'}}>
              <span style={{ width: 90, display: 'inline-block'}}>
                Open
              </span>
              <span style={{ width: 80, display: 'inline-block', textAlign: 'center' }}>
                Close
              </span>
            </div>
          </div>
          {Array.from(businessHoursState).map((weekday: BusinessHoursBase, index: any) =>
            <div key={weekday.day} style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ marginRight: 15 }}><b>{getDateString(weekday.day)} :</b></span>
              <div className={classes.formControl}>
                <div style={{ display: 'flex' }}>

                  <Select
                    labelId={getDateString(weekday.day)}
                    name={getDateString(weekday.day)}
                    id={getDateString(weekday.day)}
                    value={weekday.openTime ? weekday.openTime : 'Close'}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleChange(e, index, 'openTime')}>
                    <MenuItem value="Close">
                      <em>Close</em>
                    </MenuItem>
                    {getHoursList.map((time: any) =>
                      <MenuItem key={Math.floor(Math.random() * Math.floor(defaultKey))} value={time.military}>{time.twelvesHour}</MenuItem>
                    )}
                  </Select>
                  {weekday.openTime &&
                    <>
                      <span style={{ paddingLeft: 10, paddingRight: 10 }}> - </span>
                      <Select
                        labelId={getDateString(weekday.day)}
                        name={getDateString(weekday.day)}
                        id={getDateString(weekday.day)}
                        value={weekday.closeTime ? weekday.closeTime : 'Close'}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleChange(e, index, 'closeTime')}>
                        <MenuItem value="Close">
                          <em>Close</em>
                        </MenuItem>
                        {getHoursList.map((time: any) =>
                          <MenuItem key={Math.floor(Math.random() * Math.floor(defaultKey))} value={time.military}>{time.twelvesHour}</MenuItem>
                        )}
                      </Select>
                    </>
                  }
                </div>
              </div>

            </div>
          )}
          <div className="detail-btn" >
            <ThemeButton
              text={buttonText}
              clases="tc-btn"
              color="primary"
              variant="contained"
              isDisabled={loading}
              />
          </div>

        </form>
      </div>
    </>
  )
}
