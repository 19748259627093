// @ts-nocheck
import './data-table.scss';

import * as React from 'react';

import {
  BlueCheckbox,
  EnhancedTableHead,
  TablePaginationActions,
} from '../../App.components';
import {
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { completeMultiplePrescriptions, deleteMultiplePrescriptions, getPrescriptionImage } from '../../../services/actions/prescription';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { Link } from 'react-router-dom';
import { ReactComponent as PrintIcon } from '../../../../assets/svg/print-regular.svg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { confirmationAlert } from '../../../util/systemAlert';
import { getPharmacy } from '../../../services/actions/pharmacy';
import moment from 'moment';
import { myStyles } from './tableStyles';
import print from 'print-js';
import { reduxState } from '../../../services/types/reduxStates';

const useStyles = makeStyles(() => createStyles({
  ...myStyles,
  root: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    color: '#707683',
    width: '100%',
    overflowX: 'auto',
  },
}));

interface TableData {
  id: string;
  status: string;
  patientName: string | undefined;
  patinetId: string | undefined;
  type: string;
  date: Date;
  phoneNumber: string | undefined;
  image?: string | undefined;
  hasBeenRead: boolean;
}

interface DataTableProps {
  tableData: {
    tableHeader: string[];
    tableData: TableData[];
  };
}

export const PrescriptionsDataTable: React.FC<DataTableProps> = (
  props: DataTableProps,
): JSX.Element => {
  const { pharmacy } = useSelector((state: reduxState) => state.pharmacy);
  const { tableHeader, tableData } = props.tableData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  // const [image, setImage] = React.useState<string | null>(null);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
  const numberFooter = tableHeader.length + 1;

  React.useEffect(() => {
    dispatch(getPharmacy())
  }, [dispatch]);

  const getPrescriptionImageBase64 = ({ type, prescription }: { type: string; prescription: string; }): void => {
    if (type !== 'Refill') {
      dispatch(getPrescriptionImage({
        prescription,
        pharmacy: pharmacy.id,
      })).then((result: string) => {
        print({ printable: result, type: 'image', base64: true, imageStyle: 'width:70%;' });
      }).catch((e: any) => {
        throw e;
      });
    }
  }

  const handlePrint = ({ type, prescription }: { type: string; prescription: string; }): void => {
    getPrescriptionImageBase64({
      type,
      prescription
    });
  }

  const isSelected = (name: string): boolean => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (_event.target.checked) {
      const newSelecteds = tableData.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    name: string,
  ): void => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRowsPerPage(parseInt(_event.target.value, 10));
    setPage(0);
  };

  const handleMultipleDelete = (): void => {
    const count: string = selected.length > 0 ? 'Prescriptions' : 'Prescription';

    confirmationAlert({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Deleted',
        text: `${count} deleted.`,
      },
      callback: async () => dispatch(deleteMultiplePrescriptions({ ids: selected, pharmacyID: pharmacy.id })),
      redirect: () => setSelected([]),
    });
  };


  const handleMultipleCompleted = (): void => {
    const count: string = selected.length > 0 ? 'Prescriptions' : 'Prescription';

    confirmationAlert({
      title: 'Are you sure?',
      text: 'Do you want to complete these prescriptions?',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Completed',
        text: `${count} completed.`,
      },
      callback: async () => dispatch(completeMultiplePrescriptions({ ids: selected, pharmacyID: pharmacy.id })),
      redirect: () => setSelected([]),
    });
  };

  const statusValue = (status: string): string => {
    switch (status) {
      case 'progress':
        return 'progress';
      case 'completed':
        return 'completed';
      case 'pending':
        return 'pending';
      case 'denied':
        return 'denied';
      default:
        return 'pending';
    }
  };

  const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

  return (

    <Paper className={classes.root}>
      {selected.length > 0 && 
      <EnhancedTableToolbar 
        numSelected={selected.length} 
        onHandleMultipleDelete={handleMultipleDelete}
        handleMultipleCompleted={handleMultipleCompleted}
        hasCompleteBtn
        />}
      <TableContainer>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={tableData.length}
          tabaleHeader={tableHeader}
          />
        <TableBody className={classes.tbody}>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              (row: any, index: number): JSX.Element => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Fade key={row.id} in={row}>
                  <TableRow
                    className={classes.bodyTr}
                    style={{
                      backgroundColor: !row.hasBeenRead ? 'rgba(16, 156, 241, 0.09)': 'none',
                    }}
                    // onClick={(
                    //   event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
                    // ): void => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}>
                    <TableCell
                      className={classes.matCheck}
                      component="th"
                      scope="row">
                      <span
                        onClick={(
                          event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
                        ): void => handleClick(event, row.id)}>
                        <BlueCheckbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          />
                      </span>

                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span
                        className={`ont-badge ${statusValue(row.status)
                          }`}>
                        {' '}
                        {capitalizeFirstLetter(statusValue(row.status))}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell}>
                      <div className="user-wrap">
                        <Link
                          to={`/dashboard/patient-details/${row.patinetId}`}
                          className="edit-wrap">
                          <span className="name dot-wrap" title={row.patientName}>
                            {' '}
                            {row.patientName}
                          </span>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      {row.type}
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span className="dot-wrap">
                        {moment(row.date).format('MMM. DD, YYYY')}{' '}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">

                      {moment(row.date).format('LT')}{' '}

                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <span className="dot-wrap">
                        {row.phoneNumber}
                      </span>
                    </TableCell>
                    <TableCell className={classes.matCell} align="right">
                      <div
                        className="icon-wrap">
                        <Link
                          title="View Details"
                          to={`/dashboard/prescription-details/${row.id}`}
                          style={{
                            width: '50%'
                          }}>
                          <div className="view-icon">
                            <VisibilityOutlinedIcon />
                          </div>
                        </Link>
                        {row.type !== 'Refill' && 
                          <span
                            title="Print"
                            style={{
                              width: '50%'
                            }}
                            onClick={() => handlePrint({ type: row.type, prescription: row.id })}>
                          <div className="print-icon">
                              <PrintIcon style={{
                                width: 15,
                              }}
                                         />
                            </div>
                          </span>
                        }
                        
                      </div>
                    </TableCell>
                  </TableRow>
                  </Fade>
                );
              },
            )}
          {tableData.length === 0 &&
            <TableRow style={{ height: 48 }}>
              <TableCell colSpan={7} style={{ textAlign: 'center', fontSize: '24px' }}>
                Sorry, no record found
                </TableCell>
            </TableRow>
          }
          {emptyRows > 0 &&
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={numberFooter}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              />
          </TableRow>
        </TableFooter>
      </Table>
        </TableContainer>
    </Paper>
  );
};
