import {
  PrescriptionStates,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_FAIL,
  PrescriptionDataStates,
  UnReadPrescriptionsStates,
  GET_UNREAD_PRESCRIPTIONS_SUCCESS,
  GET_UNREAD_PRESCRIPTIONS_FAIL,
} from '../types/Prescription';
import {
  PrescriptionActionType,
  PrescriptionDataActionType,
  UnReadPrescriptionsActionType,
} from '../types/actions';

const initialState: PrescriptionStates = {
  loading: true,
  prescriptions: null,
};

export const prescriptionReducer = (
  state = initialState,
  action: PrescriptionActionType,
): PrescriptionStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        prescriptions: payload,
      };
    case GET_PRESCRIPTIONS_FAIL:
      return {
        ...state,
        loading: true,
        prescriptions: null,
      };
    default:
      return state;
  }
};

const initialDetailsState: PrescriptionDataStates = {
  loading: true,
  prescription: null,
};

export const prescriptionDetailsReducer = (
  state = initialDetailsState,
  action: PrescriptionDataActionType,
): PrescriptionDataStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        prescription: payload,
      };
    case GET_PRESCRIPTION_FAIL:
      return {
        ...state,
        loading: true,
        prescription: null,
      };
    default:
      return state;
  }
};

const initialUnReadPrescriptionsState: UnReadPrescriptionsStates = {
  loading: true,
  unReadPrescriptions: null,
};

export const unReadPrescriptionsReducer = (
  state = initialUnReadPrescriptionsState,
  action: UnReadPrescriptionsActionType,
): UnReadPrescriptionsStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_UNREAD_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        unReadPrescriptions: payload,
      };
    case GET_UNREAD_PRESCRIPTIONS_FAIL:
      return {
        ...state,
        loading: true,
        unReadPrescriptions: null,
      };
    default:
      return state;
  }
};
