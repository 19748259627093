import { Pharmacist } from './Pharmacist';
import { PatientRelations } from './Patients';
import { PrescriptionRelations, PharmacyRelations } from './User';
import { UnReadPrescriptionRelation } from './Prescription';

export const GET_PHARMACIES_SUCCESS = 'GET_PHARMACIES_SUCCESS';
export const GET_PHARMACIES_FAIL = 'GET_PHARMACIES_FAIL';

export const DELETE_MULTIPLE_PHARMACIES_SUCCESS =
  'DELETE_MULTIPLE_PHARMACIES_SUCCESS';
export const DELETE_MULTIPLE_PHARMACIES_ERROR =
  'DELETE_MULTIPLE_PHARMACIES_ERROR';
export interface Pharmacy {
  id: string;
  directoryId: string;
  logo: string;
  shopper: string;
  name: string;
  address: string;
  town: string;
  state: string;
  zipCode: string;
  longitude?: string;
  latitude?: string;
  phoneNumber: string;
  faxNumber?: string;
  email: string;
  hasRx30: boolean;
  hasDelivery: boolean;
  webSite: string;
  rx30Number: string;
  isActive: boolean;
  createdAt: Date;
  hasAutoRefill: boolean;
  facebook: string;
  instagram: string;
  twitter: string;
  userInfoIsRequired?: boolean;
  pharmacists?: Pharmacist[];
  patients?: PatientRelations[];
  prescriptions?: PrescriptionRelations[];
  reportCount: ReportCount;
  businessHours: BusinessHours[];
  unReadprescriptions?: UnReadPrescriptionRelation[];
}

export interface BusinessHours {
  id: string;
  day: number;
  openTime: string;
  closeTime: string;
  pharmacy: PharmacyRelations;
}

export interface BusinessHoursRTO extends BusinessHoursBase {
  id: string;
}
export interface BusinessHoursBase {
  day: number;
  openTime: string | null;
  closeTime: string | null;
}

export interface ReportCount {
  patientCount: PatientCount;
  prescriptionCount: PrescriptionCount;
  pharmacistsCount: number;
}

export interface PatientCount {
  count: number;
  masculines: number;
  feminines: number;
}

export interface PrescriptionCount {
  count: number;
  completed: number;
  uploadToday: number;
}
export interface DataSetType {
  data: number[] | null;
  backgroundColor: string[] | null;
  hoverBackgroundColor: string[] | null;
}

export interface ChartDataType {
  labels: string[] | null;
  datasets: DataSetType[] | null;
}

export interface PharmacyStates {
  loading: boolean;
  pharmacy: Pharmacy | null | undefined | any;
}

export interface GetPharmacyAction {
  type: typeof GET_PHARMACIES_SUCCESS;
  payload: Pharmacy;
}

export interface ErrorGetPharmacyAction {
  type: typeof GET_PHARMACIES_FAIL;
  payload: Pharmacy;
}

export interface DeleteMultiplePharmaciesAction {
  type: typeof DELETE_MULTIPLE_PHARMACIES_SUCCESS;
  payload: Pharmacy[];
}

export interface DeleteMultiplePharmaciesError {
  type: typeof DELETE_MULTIPLE_PHARMACIES_ERROR;
  payload: Pharmacy[];
}
