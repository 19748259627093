import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { Loading, PatientsDataTable } from '../../layout/App.components';
import { PatientRelations } from '../../services/types/Patients';
import { cryptoService } from '../../util/crypto';
import { sortByDate } from '../../util/helpers';
import { getPharmacyPatients } from '../../services/actions/patinet';
import { TableSearchOptions, TableSearch } from '../../layout/ui/search/TableSearch';
import Fuse from 'fuse.js';


interface Data {
  id: string;
  status: boolean;
  username: string;
  phoneNumber: string;
  email: string;
}

const createUserData = (
  id: string,
  status: boolean,
  username: string,
  phoneNumber: string,
  email: string,
): Data => ({ id, status, username, phoneNumber, email });

export const PatientsListPage: React.FC = (): JSX.Element => {
  const { patients, loading } = useSelector((state: reduxState) => state.patients);
  const [query, updateQuery] = React.useState('');
  

  const dispatch = useDispatch();


  React.useEffect(() => {
    dispatch(getPharmacyPatients());
  }, [dispatch]);



  let rows: Data[] = [];

  if (!loading && patients) {
    rows = sortByDate(patients)
      .map((patient: PatientRelations) => {
        const patientName = `${cryptoService.decrypt(patient.firstName)} ${cryptoService.decrypt(patient.lastName)}`;
        return createUserData(
          patient.id,
          patient.isActive,
          patientName,
          patient.phoneNumber,
          patient.email,
        );
      });

      
  }

  const tableHeader = [
    'Status',
    'Username',
    'Phone Number',
    'Email',
    'View',
  ];



  const options: TableSearchOptions = {
    list: rows,
    keys: [
      'username', 
      'phoneNumber', 
      'email'
    ]
  }

  const { list, keys } = options;
  const fuse = new Fuse(list, { keys });

  const results = fuse.search(query);
  const searchResults = query ? results.map(res => res.item) : rows;

  const tableOption = {
    tableHeader,
    tableData: searchResults,
  };

  return (
    <>
      {loading || !patients ?
        <Loading />
        : <>
          <TableSearch updateQuery={updateQuery} />
          <PatientsDataTable tableData={tableOption} />
        </>
      }
    </>
  )
}
