import {
  GET_PATIENTS_FAIL,
  GET_PATIENTS_SUCCESS,
  GET_PATIENT_FAIL,
  GET_PATIENT_SUCCESS,
} from '../types/Patients';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { GET_PRESCRIPTION_SUCCESS } from '../types/Prescription';
import { LOGIN_FAIL } from '../types/User';
import { NotePost } from '../types/Note';
import axios from 'axios';
import { loadUser } from './auth';
// import { getPrescriptions } from './prescription';
import { setErrorAlert } from './alert';

export const getPatient = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_HOST}/pharmacy_admin/patients/${id}`,
    );
    dispatch(loadUser());
    dispatch({
      type: GET_PATIENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PATIENT_FAIL,
    });
  }
};

export const addNote = (
  data: NotePost,
  type: 'patient' | 'prescription',
): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const uri =
    type === 'patient'
      ? `${API_HOST}/pharmacy_admin/patients/add_note`
      : `${API_HOST}/pharmacy_admin/prescriptions/add_note`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.post(uri, body, config);

    dispatch(loadUser());
    if (type === 'patient') {
      dispatch(getPatient(response.data.id));
    }

    if (type === 'prescription') {
      dispatch({
        type: GET_PRESCRIPTION_SUCCESS,
        payload: response.data,
      });
    }

    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const updatePatientStatus = (data: {
  id: string;
  isActive: boolean;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/pharmacy_admin/patients/status`,
      body,
      config,
    );
    dispatch(getPatient(response.data.id));
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message: error.response.data ? error.response.data.message : '',
    };
  }
};

export const getPharmacyPatients = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    id: localStorage.pharmacy,
  });

  try {
    const response = await axios.post(
      `${API_HOST}/pharmacy_admin/patients/by_pharmacy`,
      body,
      config,
    );

    dispatch({
      type: GET_PATIENTS_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch(setErrorAlert(error.response.data.message));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: GET_PATIENTS_FAIL,
    });
  }
};

export const getPatienImage = (data: {
  pharmacy: string;
  patient?: string;
  imageType: string;
  dependent?: string;
}): any => async (dispatch: Dispatch<AnyAction>): Promise<string> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);

  const response = await axios.post(
    `${API_HOST}/pharmacy_admin/patients/image`,
    body,
    config,
  );

  dispatch(loadUser());
  return response.data;
};
